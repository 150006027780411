import React from 'react';
import { ThemeProvider } from '../config';
import Navigation from '../navigation'; 
 
import { CoreState } from '../project/context/CoreContext';
import { ThemeContext } from '../project/context/ThemeContext';

import {initNavigation, setKeyMap} from '@noriginmedia/react-spatial-navigation';

const App = () => {
    // initNavigation()
    initNavigation({
        debug: false,
        // visualDebug: true
    })

    // setKeyMap({
    //     left: 37,
    //     up: 38,
    //     right: 39,
    //     down: 40,
    //     enter: 13
    // });

    // setKeyMap({
    //     'left': 9001,
    //     'up': 9002,
    //     'right': 9003,
    //     'down': 9004,
    //     'enter': 9005
    //   });

    return (
        <ThemeProvider>
            <CoreState>
                <ThemeContext>
                    <Navigation />
                </ThemeContext>
            </CoreState>
        </ThemeProvider>
    )
};

export default App;
