import React from "react";  

import { 
 
    VideoImage,
    VideoInfos,
    VideoInfosContent,
    VideosInfosText,
    VideosInfosTitle

} from './styled'   

export default function VideoInformations({ details }){   
    return ( 
        <>   
            <VideoInfos>
                <VideoImage source={details?.thumb} />
                <VideoInfosContent>
                    {/* <VideosInfosText>{details?.title}</VideosInfosText> */}
                    <VideosInfosTitle>{details?.title}</VideosInfosTitle>
                </VideoInfosContent>
            </VideoInfos>
        </>
    )
} 
