import React, { useContext, useEffect, useRef, useState } from "react"; 

import { 
    ContentBody,
    BodyPrincipal, 
    WrapRec  ,
    WrapInput,
    ContainerLoad
} from './styled' 
 
import VideosRow from "../VideosRow";

import SearchThemes from "../Search/Themes";
import SearchCharacteres from "../Search/Characteres";
import SearchInput from "../Search/Input";
import { getScaledValue, Load } from "../../ui/styled";
import { ReadKids, ReadSearch, ReadSearchByCharacter, ReadSearchByTheme, ReadSerchableCharacteres, ReadSerchableThemes } from "../../services/catalog";
import { parseVideo } from "../../utils/parser";
import { ReadMyHistory } from "../../services/account";
import { CoreContext } from "../../context/CoreContext";
import { AddSearchHistory, ReadSearchHistory } from "../../services/history";

export default function PageSearch({ wrapped }){ 
    
    const sliderRef = useRef()

    const SECTION_SIZE = 3

    const handleArrowPress = (direction, key) => {    

        const focusSliderVertical = (nextSlide) => {
            if (sliderRef.current) {
                const value = getScaledValue(125) * nextSlide;
                sliderRef.current.style.top = `-${value}px`;
            }
        };

        switch (direction) { 
            case "down": {
                const nextSlide = Number(key) + 1;
                if (nextSlide > SECTION_SIZE - 1) return;
                return focusSliderVertical(nextSlide);
            }
            case "up": {
                const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSliderVertical(nextSlide);
            }
            default:
                return;
        };
    } 

    const { authentication, currentProfile } = useContext(CoreContext)
    
    const [loading, setLoading] = useState(false)
    const [searched, setSearched] = useState('')
    const [foundedVideos, setFoundedVideos] = useState([])

    const search = async (searchExpression) => {
        
        setSearched(searchExpression)
        setLoading(true)
        const result = await ReadSearch(searchExpression)
        const addded = await AddSearchHistory({ data:{ term: searchExpression } }, currentProfile.id, authentication.jwt)

        if(addded?.id){ reloadSearched() ;}

        if(result?.data?.length){  
            setFoundedVideos((currentProfile?.kids ? result.data?.filter(f => allowedVideos.includes(f.id) ) : result.data).map(parseVideo)) 
        }else{
            setFoundedVideos([])
        } 
        setLoading(false)

    }


    const [themes, setThemes] = useState([ ])
    const [users, setUsers] = useState([ ])
    const [myHistory, setMyHistory] = useState([])
    const [previousSearchs, setPreviousSearchs] = useState([])

    const [allowedVideos, setAllowedVideos] = useState([])

    const init = async () => {

        setLoading(true)
        // const resultThemes = await ReadSerchableThemes()
        // const resultCharacteres = await ReadSerchableCharacteres()
        const resultHistory = currentProfile ? await ReadMyHistory(currentProfile.id, authentication.jwt) : false 

        if(currentProfile?.kids){
            const resultKids = await ReadKids()
            if(resultKids?.data?.[0]?.attributes?.stream_shows?.data?.length){
                setAllowedVideos(resultKids?.data?.[0]?.attributes?.stream_shows?.data?.map(m => m.id))
            }
        }
        
        // if(resultThemes?.data?.length){ setThemes( resultThemes?.data?.map( item => ({ ...item, title:item?.descriptor }) ) ) ;}
        // if(resultCharacteres?.data?.length){ setUsers( resultCharacteres?.data?.map( item => ({ ...item, image: { uri:item?.picture?.url } }) ) ) ;}
        if(resultHistory?.data?.length){ setMyHistory( resultHistory?.data?.map( mit => mit.stream_show )?.map( parseVideo ) ) ;}

        await reloadSearched()

        setLoading(false)
    }

    const reloadSearched = async () => {
        const resultPrevious = currentProfile ? await ReadSearchHistory(currentProfile.id, authentication.jwt) : false 
        if(resultPrevious?.data?.length){ 
            setPreviousSearchs( 
                resultPrevious?.data?.sort(
                    (a,b) => new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
                )?.filter((fit, fik) => fit.term && fik < 5) 
            ) ;
        }
    }

    const searchBy = async (type, item) => {
        
        setLoading(true)
        
        setSearched( item.title ? item.title : item.name )
        const result = (type === 'theme') ? await ReadSearchByTheme(item.id) : await ReadSearchByCharacter(item.id)
        await AddSearchHistory({ data:{ [ (type === 'theme') ? 'themes' : 'characters' ]: item.id } }, currentProfile.id, authentication.jwt)

        if(result?.data?.length){ 
            setFoundedVideos(result.data.map(parseVideo)) 
        }else{
            setFoundedVideos([])
        } 
        setLoading(false)

    }

    useEffect(() => {
        init()
    }, [currentProfile])

    return (
        <> 
            <ContentBody ref={sliderRef}> 
                {
                    loading ? 
                        <ContainerLoad>
                            <Load marginLeft />
                        </ContainerLoad>
                    : 
                        <BodyPrincipal wrapped={wrapped}>  
                            <WrapInput> 
                                <SearchInput searched={searched} wrapped={wrapped} onSearch={search} lastSearch={previousSearchs} />   
                            </WrapInput>
                            <WrapRec wrapped={wrapped}>
                                {
                                    searched ? <VideosRow title={ foundedVideos?.length ? `Resultados para: ${ searched }` : `Sem resultados para: ${ searched }`} videos={foundedVideos} handleVertical={a => handleArrowPress(a, 0)} /> : <>
                                        {/* <SearchThemes themes={themes} handleVertical={a => handleArrowPress(a, 0)} searchBy={searchBy}  /> */}
                                        {/* <SearchCharacteres users={users} handleVertical={a => handleArrowPress(a, 1)} searchBy={searchBy} />  */}
                                        { !myHistory?.length ? null : <VideosRow title={'Últimos visualizados'} handleVertical={a => handleArrowPress(a, 0)} videos={myHistory} /> }
                                    </>
                                }
                            </WrapRec>
                        </BodyPrincipal>
                }
            </ContentBody> 
        </>
    )
} 