import React, { useCallback, useContext, useEffect, useRef, useState } from "react";   

import { 
    ContainerScroll,  
    ContainerVideo,
    VideoContainer,
    VideoDegrade,
    VideoVideo, 
    VideoTouch,
    VideoContainerPadding,
    Container
} from './styled'  

import OverlayInfos from "./OverlayInfos";

import VideoRecommendations from "./Recommendation";
import VideoRecommendationSide from "./RecommendationSide";
import VideoHeader from "./Header";
import { ReadPreviewsShow, ReadShowDetails, ReadProgressPlayer, CreateProgressPlayer, UpdateProgressPlayer } from "../../services/catalog";
import { exposeStrapiError, parseStrapiImage } from "../../utils";
import { parseVideo, parseVideoShow } from "../../utils/parser";
import { Load, getScaledValue } from "../../ui/styled";
import { CoreContext } from "../../context/CoreContext";
import { AddHistory } from "../../services/account";

import useSocket from "../../hooks/useSocket";

import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { useNavigate } from "@reach/router";
import useRemoteControl from "../../hooks/useRemoteControl";

export default withFocusable({
    trackChildren: true, 
    forgetLastFocusedChild: false, 
})(function VideoPlayer({ setFocus, ...props }){   


    const navigate = useNavigate(props);

    const wrapped = false
    const videoRef = useRef()
    const [playing, setPlaying] = useState(false)
    const [videoUrl, setVideoUrl] = useState('')

    const { user, currentVideo, authentication, setAuthentication, currentProfile, setCurrentVideo, isOnline } = useContext(CoreContext)

    const { connected, emitPlaying, emitStoping } = useSocket()

    const playPause = useCallback(() => { 
        
        // console.log("playPause", videoUrl, videoRef?.current, playing )
        if( videoUrl && typeof videoRef?.current?.play === 'function' ){
            if( !videoRef.current?.paused ){
                videoRef.current.pause()
                setFocus('play-button')
            } else {
                videoRef.current.play()
                setFocus('play-button')
            }
        }

    }, [playing, videoRef?.current, videoUrl])

    const stop = () => { 
        console.log("stop")
        if( videoUrl && typeof videoRef?.current?.play === 'function' ){
            videoRef.current.pause()
            setFocus('play-button')
            setSeek(0)
        }
    }

    const handleRemote = useCallback(code => {

        const ct = videoRef?.current?.currentTime || 0

        console.log("handleRemote code", code, ct)

        const justPause = () => {
            videoRef.current.pause()
            setTimeout(() => setFocus('play-button'), 300)
            setPlaying(false)
        }

        const justPlay = () => {
            videoRef.current.play()
            setPlaying(true)
        }

        const plPause = () => {
            console.log("plPause", videoRef.current?.paused )
            if( !videoRef.current?.paused ){
                justPause()
            } else {
                justPlay()
            }
        }
        
        if( code === 415 ){
            // play
            justPlay()
            return;
        }

        if( code === 19 ){
            // pause
            justPause()
            return;
        }

        if( code === 10252 ){
            plPause()
            return;
        }
        
        if(code === 412 || code === 37 || code === 10232){
            // prev button 
            const nextTime = ct - 7
            setSeek( nextTime > 0 ? nextTime : 1) 
            if( !(nextTime >= 0) ){ 
                // less is more, but if they want to back video when second 0 ...
                // if(prevStream){
                //     prevShow()
                // }
            }
            return;
        }
        
        if(code === 418 || code === 417 || code === 39 || code === 10233){
            // next button 
            setSeek(ct + 5)
            return;
        }
        
        if(code === 413){
            // stop button 
            const hasStopped = document.getElementById("stopped")
            if( !videoRef.current?.paused && !hasStopped ){
                justPause();
                const stopElement = document.createElement('div')
                stopElement.id = "stopped"
                document.body.append(stopElement)
            }else{
                hasStopped.remove()
                navigate("/catalog")
            }
            return;
        }
        
        if(code === 10009 || code === 461 || code === 27 || code === 8 || code === 10182 ){
            if(playing){
                plPause()
            }else{
                navigate("/catalog")
            }
            return;
        }
            
        if( ( (code === 38 || code === 40) && playing) || ( code === 13 && !videoRef.current?.paused ) ){
            plPause()
            return;
        }

    }, [playing])

   const { pressed } = useRemoteControl(handleRemote)
    
    // useEffect(() => {
    //     if( videoUrl ){
    //         if(playing){
    //             videoRef?.current?.play()
    //             setFocus('pause-button')
    //         } else {
    //             videoRef?.current?.pause()
    //             setFocus('play-button')
    //         }
    //     }
    // }, [playing, videoUrl]) 

    const [loading, setLoading] = useState(false)
    const [relateds, setRelateds] = useState([])
    const [details, setDetails] = useState({}) 

    const [nextStream, setNextStream] = useState(null)
    const [prevStream, setPrevStream] = useState(null)
    
    const [ videoId, setVideoId ] = useState(null)

    const loadVideo = async () => {
        setLoading(true)

        const nvideoObj = { ...currentVideo }
        setVideoId(currentVideo?.id) 

        const result = await ReadShowDetails(currentVideo?.id, authentication?.jwt)
        const prevShow = await ReadPreviewsShow(currentVideo?.id, authentication?.jwt)
        const tracked = await AddHistory(currentProfile?.id, { data:{ stream_show:currentVideo?.id } },  authentication.jwt )



        const progressPlayer = await ReadProgressPlayer(currentVideo?.id, currentProfile?.id, authentication?.jwt)
        if(progressPlayer?.data?.length){
            const cProg = progressPlayer?.data
                ?.sort((a, b) => new Date(b?.attributes?.updatedAt).getTime() - new Date(a?.attributes?.updatedAt).getTime()  )
                    ?.filter(f => !f?.attributes?.data?.finishing)?.[0]
            setCurrentProgressPlayer(cProg)
            if(!cProg?.attributes?.data?.finishing){
                const progress = cProg?.attributes?.progress
                // console.log("RECOVERED progress", progress)
                setSeek(progress)
            }
        }


        console.log('result', result)

        if(!exposeStrapiError(result) && result?.stream_master_file?.url){
            // console.log(`${ AKAMAI_ENDPOINT }${ result?.stream_master_file?.hash }${ result?.stream_master_file?.ext }`, result?.stream_master_file?.url)
            // setVideoUrl(`${ AKAMAI_ENDPOINT }${ result?.stream_master_file?.hash }${ result?.stream_master_file?.ext }`)

            setFocus('play-button')
            setVideoUrl(`${ result?.stream_master_file?.url }`)
            setCurrentVideo(null)
        }else{
            if(result?.limit){
                setCurrentVideo(nvideoObj)
                navigate(`/limit`)
            }else{
                setAuthentication(null)                
                setCurrentVideo(null)
                navigate(`/`)
            }
        }


        if(prevShow?.data?.[0]?.id){
            setPrevStream(prevShow?.data?.[0])
        }

        if(result?.next_stream?.id){
            setNextStream(result?.next_stream)
        }

        if(result?.related_shows?.length){  
            setRelateds(result.related_shows?.map(parseVideo))
        }
        if(result.id){ setDetails(parseVideoShow(result)) }
        setLoading(false)
    }

    const nextShow = () => { 
        setCurrentVideo(nextStream)
    }
    
    const prevShow = () => {  
        setCurrentVideo(prevStream)
    }
    
    useEffect(() => {
        console.log("isOnline", isOnline, currentVideo)
        if(currentVideo?.id && isOnline){ 
            loadVideo() ;
        } else{
            if(isOnline){
                videoRef?.current?.play()
            }else{
                videoRef?.current?.pause()
            }
        }
    }, [currentVideo, isOnline])

    useEffect(() => {
        if(videoUrl){ setTimeout(playPause, 100) ;}
    }, [videoUrl]); 

    useEffect(() => {
        // console.log(videoRef, videoRef.current, videoUrl, nextStream)
        if( videoUrl && typeof videoRef?.current?.addEventListener === 'function' ){
                const fireOnVideoEnd = () => { setCurrentVideo(nextStream) ;}
                videoRef?.current?.addEventListener('ended', fireOnVideoEnd);
            return () => {
                videoRef?.current?.removeEventListener('ended', fireOnVideoEnd); 
            }
        }
    }, [ nextStream, videoUrl, videoRef?.current ]);

    // useEffect(() => {
    //     if(playing){
    //         emitPlaying(videoId)
    //     } else {
    //         emitStoping(videoId)
    //     }
    // }, [playing])

    useEffect(() => {
        if(playing){
            emitPlaying(videoId)
        }
    }, [playing])

    useEffect(() => {
        return () => { emitStoping(videoId) }
    }, [])

    const handleArrow =  e => {
        console.log("handle arrow", e)
        // if(e === "up" || e === "down"){
        //     playPause()
        // }
        // if(e === "right"){
        //     setSeek(currentTime + 5)
        // }
        // if(e === "left"){
        //     setSeek(currentTime - 5 > 0 ? currentTime - 5 : 0)
        // }
    }
    
    const [currentProgressPlayer, setCurrentProgressPlayer] = useState(null)
    const [currentTime, setCurrentTime] = useState(0)
    const [seek, setSeek] = useState(0)
    const [duration, setDuration] = useState(0)

    const onSeek = time => {
        if(videoRef?.current){
            videoRef.current.currentTime = time;
        }
    }

    useEffect(() => {
        const video = videoRef.current;

        const updateTime = () => {
            setCurrentTime(video.currentTime);
            setDuration(video.duration);
        }

        if(video){
            video?.addEventListener('timeupdate', updateTime) 
            return () => {
                video?.removeEventListener('timeupdate', updateTime) 
            }
        }
    }, [ videoRef?.current ])

    const [waiting, setWaiting] = useState(false)

    useEffect( () => { 

        const saveProgress = async () => {
            if(currentTime && user && currentProfile?.id && videoId && !waiting && playing){
                setWaiting(true)
                const payload = {
                    data:{
                        progress: parseInt(currentTime),
                        profile: currentProfile?.id,
                        stream_show: videoId,
                        data:{
                            user,
                            profile: currentProfile,
                            duration,
                            finishing: ((parseInt(currentTime) + 240) > duration) 
                        }
                    }
                }
                const created = currentProgressPlayer?.id ? await UpdateProgressPlayer(currentProgressPlayer?.id, payload, authentication?.jwt) : await CreateProgressPlayer(payload, authentication?.jwt)
                console.log("Progress SAVED", created?.data?.attributes?.progress)
                setTimeout(() => { setWaiting(false) ;}, 15000)
            }
        }

        saveProgress()

    }, [ currentTime, currentProfile, waiting, videoId ])

    useEffect( () => { 
        if( videoRef?.current && playing && seek ){
            setSeek(0);
            onSeek(seek)
        }
    }, [ playing, seek ])

    return ( 
        <>  
            <Container> 
                {
                    loading ? 
                        <ContainerVideo purple> 
                            <Load margin={"auto"}/>  
                        </ContainerVideo> 
                    : <>
                        <VideoTouch focusKey={'pause-button'}>
                            <>
                                
                                { 
                                    videoUrl ? 
                                        <video 
                                            ref={videoRef} 
                                            autoPlay={false}
                                            controls={false}
                                            height={'100%'} 
                                            width="100%"
                                            onPause={() => setPlaying(false)} 
                                            onPlay={() => setPlaying(true) }  
                                            data-has-stopped="0"
                                            style={{ objectFit:'contain' }} >
                                            <source src={parseStrapiImage(videoUrl)} type="video/mp4" /> 
                                        </video> : null
                                }

                                {/* 
                                {
                                    videoUrl ? 
                                        <ReactHlsPlayer
                                            playerRef={videoRef}
                                            src={videoUrl}
                                            autoPlay={false}
                                            controls={true}
                                            width="100%"
                                            height="100%"
                                            onPause={() => setPlaying(false) }
                                            onPlay={() => setPlaying(true) }
                                            style={{ objectFit:'fill' }}
                                        />
                                    : null
                                } 
                                */}
                                
                            </>
                        </VideoTouch>
                        {
                            playing ? null : 
                            <ContainerVideo wrapped={wrapped}> 
                                <VideoDegrade>
                                    <VideoContainer>
                                        <VideoContainerPadding>
                                            <VideoHeader details={details} />
                                            <OverlayInfos details={details} playPause={() => playPause() } nextShow={nextStream ? nextShow : null} prevShow={prevStream ? prevShow : null} />
                                            {/* <ProgressBar />  */}
                                        </VideoContainerPadding>
                                        { !wrapped ? <VideoRecommendations relateds={relateds} /> : null }
                                    </VideoContainer>  
                                </VideoDegrade>
                                { wrapped ? <VideoRecommendationSide relateds={relateds} /> : null }
                            </ContainerVideo>
                        }
                    </>
                }
                {/* <div style={{ position:'absolute', background:'white', top: 0, right: 0, height: `${ getScaledValue(10) }px`, width: `${ getScaledValue(100) }px` }}>
                    { pressed?.join('\n') }
                </div>  */}
            </Container>
        </>
    )
})


