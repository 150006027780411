import React, { useContext, useEffect } from "react"; 
// import { getScaledValue } from 'renative'; 
import { getScaledValue } from '../../ui/styled';

import { 
    ContentBody,
    BodyPrincipal,
    BodyMenu, 

    FavoriteDecoration,
    FavoriteContainer,
    FavoriteContent,
    FavoriteIcon,
    FavoriteText,
    BodyContainer
} from './styled' 

import ButtonMenu from "../../components/MenuButton"; 
import VideoCategory from "../../components/VideoCategory";
import Banner from "../../components/Banner"; 
import VideosRow from "../VideosRow";
import FavoritesEmpty from "../Favorites/Empty";

import { availableContents, availableCatagories, availableVideos } from "../../services/mock"; 
import { CoreContext } from "../../context/CoreContext";

export default function PageFavorite(){ 
    
    const { favorites, reloadFavorites } = useContext(CoreContext)

    useEffect(() => { reloadFavorites() ;}, [])

    return ( 
        <> 
            {
                favorites.length === 0 ? <>
                    <FavoritesEmpty /> 
                </> : <>
                    <ContentBody>
                        <BodyPrincipal>
                            <BodyMenu> 
                                <ButtonMenu item={{ id:1, title:'Favoritos', icon:{ icon:'heart', width:getScaledValue(13), height:getScaledValue(11), fill:'#ffffff' } }} />  
                            </BodyMenu> 
                            <BodyContainer>
                                <VideosRow videos={favorites} />
                            </BodyContainer>
                        </BodyPrincipal> 
                    </ContentBody> 
                </>
            }
        </>
    )
} 