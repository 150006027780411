import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

// import { getScaledValue } from 'renative'; 

export const Container = styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  
 
    background: #000000; 
    
    flex-direction: row;
    z-index:3;
`;     

export const Spacer = styled.View.attrs({
})`   
    padding: ${ getScaledValue(20) }px; 
`;     


export const ContentBody = styled.View.attrs({
    showsVerticalScrollIndicator:false
})`     
    background: #000000;
    flex:1; 
`;     

 

export const BodyPrincipal = styled.View.attrs({
})`     
    padding: 0 0 ${ getScaledValue(20) }px; 
    overflow: hidden;
`;  



export const BodyMenu = styled.View.attrs({
})`     
    padding:  ${ getScaledValue(20) }px 0 0 ${ getScaledValue(20) }px; 
    flex-direction: row;
`;   



export const CenterLoadMore = styled.View.attrs({
})`     
    margin: 0 auto;
`;   