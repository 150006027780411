import React, { useContext, useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, getScaledValue } from 'renative';

import {
    Container 
} from './styled'

import { View } from 'react-native';
// import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config'; 

import { availableCatalogs } from "../../services/mock";
import { CoreContext } from "../../context/CoreContext";

import Side from "../../components/Side";
import PageSearch from "../../components/PageSearch";
import PageHome from "../../components/PageHome";
import PageFavorite from "../../components/PageFavorite";
import VideoPlayer from "../../components/VideoPlayer";




import ModalSelectProfile from "../../components/Modal/SelectProfile";
import ModalAlert from "../../components/Modal/Alert";
import ModalCore from "../../components/Modal";
import SideMore from "../../components/SideMore";

// const FocusableView = hasWebFocusableUI ? withFocusable()(View) : View;

import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import useRemoteControl from "../../hooks/useRemoteControl";

export default withFocusable({
    trackChildren: true, 
    forgetLastFocusedChild: false, 
})(function Catalog(props){

    const navigate = useNavigate(props);

    const pages = {
        'search': 0,
        'catalog': 1,
        'favorites': 2
    }

    const page = Object.keys(pages).find( i => window.location.href.indexOf(i) !== -1 ) 
    const pageKey = pages[page]

    const { currentVideo, modal, setModal, side, setSide, currentProfile, setCurrentVideo } = useContext(CoreContext)
    const [currentCatalog, setCurrentCatalog] = useState(availableCatalogs[pageKey])


    const handleRemote = useCallback( code => { 
        if(code === 10009 || code === 461 || code === 27 || code === 8 || code === 10182 ){ 
            if(currentCatalog?.id === 2){
                navigate("/select-profile") 
            } else{
                selectCatalog(availableCatalogs?.[1])
            }
        }
    }, [currentCatalog])

   const { pressed } = useRemoteControl(handleRemote)

    const blurredRef = useRef()

    const selectCatalog = item => {
        // setCurrentCatalog(item)

        navigate(`/${ item.location }`)
    }
    
    const goOut = () => {
        setCurrentVideo(null)
        navigate(`/`)
    }

    const selectProfile = () => {
        if(currentProfile?.kids){
            goOut()
        }
        setModal({ type: 'select-profile'})
    }
 
    const message = {
        closeable: true,
        title: 'Sem Conexão!',
        text: 'Algo errado com a sua conexão de internet.',
        action: {
            action: null,
            title:'Tentar novamente'
        },
        icon: {
            icon:'face',
            width: getScaledValue(40),
            height: getScaledValue(40),
            fill: '#fff'
        }
    }

    const messageInfo = {
        title: 'Ops!',
        text: `Estamos com dificuldades com a sua forma de pagamento.\n\nVerifique seus dados de pagamento no aplicativo HoxxTV.`,
        action: {
            action: null,
            title:'Fechar'
        },
        icon: {
            icon:'ops',
            width: getScaledValue(30),
            height: getScaledValue(40),
            fill: '#fff'
        }
    }

    const handleBlurred = () => {
        // console.log('Blurred')
        if( typeof props.setFocus === 'function'){
            clearTimeout(blurredRef.current)
            blurredRef.current = setTimeout(() => {
                if( props.path === "/catalog" ){
                    props.setFocus(`sideoption-${ pageKey + 1 }`)
                }
            }, 1000)
        }
    }

    const closeSide = () => {
        setSide(null)
    }

    useEffect(() => {  
        if(!currentVideo && !modal){
            props.setFocus(`sideoption-${ pageKey + 1 }`)
        }
    }, [currentVideo, modal]);

    useEffect(() => {  
        if(currentVideo){
            navigate('/video')
        }
    }, [currentVideo]);

    useEffect(() => {  
        if(!currentProfile){
            navigate('/')
        }

        // if (window.focus) window.focus();
        // setModal({ type:'alert', ...message })
    }, []);

    return (
        <>
                <Container onBecameBlurred={handleBlurred}>  
                    {
                        currentVideo ? <>
                            {/* <VideoPlayer setFocus={props.setFocus} goOut={goOut} /> */}
                        </> : <>
                            { availableCatalogs && <Side availableCatalogs={availableCatalogs} selectCatalog={selectCatalog} selectProfile={selectProfile} currentCatalog={currentCatalog} /> }
                            { currentCatalog?.id === 1 ? <PageSearch wrapped={false} /> : null }
                            { currentCatalog?.id === 2 ? <PageHome setFocus={props.setFocus} /> : null }
                            { currentCatalog?.id === 3 ? <PageFavorite /> : null }
                        </>
                    }
                    {/* { modal ? <ModalCore setFocus={props.setFocus} /> : null } */}

                    { side ? <SideMore onBecameBlurred={closeSide} /> : null }
                </Container> 
        </>
    )
});