import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

 

export const ContentModalOut = styled.View.attrs({
})` 
`;

export const ContentModalTitle = styled.Text.attrs({
})` 
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(24) }px;
    color: #ffffff;
    text-align: center;
    margin-bottom: ${ getScaledValue(14) }px;
`;

export const ContentModalIn = styled.View.attrs({
})`
    padding: ${ getScaledValue(15) }px;
    background: #ffffff;
    flex-direction: row;
    align-items: center;
`;



export const UserContent = styled.TouchableOpacity.attrs({
})` 
    align-items: center;
    margin: 0 ${ getScaledValue(14) }px;
`;
export const UserContentImage = styled.ImageBackground.attrs({
})` 
    width: ${ getScaledValue(70) }px;
    height: ${ getScaledValue(70) }px;
    border-radius: ${ getScaledValue(35) }px;
    background: rgba(0,0,0,.5);
    margin-bottom: ${ getScaledValue(11) }px;

    border-width: 3px;
    border-color: #f95e14;
`;
export const UserContentText = styled.Text.attrs({
})`
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(12) }px;
    color: #A7A7A7;
    ${
        props => props.active ? `
            color: #f95e14;
        ` : ``
    }
`;