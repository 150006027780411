import React, { useContext, useEffect, useMemo } from "react";
import { CoreContext } from "../../context/CoreContext";
import ModalAlert from "./Alert";
import ModalSelectProfile from "./SelectProfile";
import { getScaledValue } from "../../ui/styled";

export default function ModalCore({ setFocus }){

    const { modal } = useContext(CoreContext)

    const defaultContent = useMemo(() => {
        return modal?.type === 'alert' ? modal : {
            closeable: true,
            title: 'Sem Conexão!',
            text: 'Algo errado com a sua conexão de internet.',
            action: {
                action: null,
                title:'Tentar novamente'
            },
            icon: {
                icon:'face',
                width: getScaledValue(40),
                height: getScaledValue(40),
                fill: '#fff'
            }
        }
    }, [modal])

    return (
        <>
            <ModalSelectProfile setFocus={setFocus} opened={modal?.type === 'select-profile'} />
            <ModalAlert {...defaultContent} setFocus={setFocus} opened={modal?.type === 'alert'} />
        </>
    )
}