import { GET, POST } from './api' 
 
export const DoLogin = async params => { 
	const response = await POST(`/auth/code`, params) 
	return response
} 

export const ReadMe = async (token) => {
	return await GET(`/me?populate[0]=active_profile&populate[active_profile][populate][0]=picture`, token)
}  

export const ReadMultiScreen = async (id) => {
	return await GET(`/multi-screens?populate=*&filters[user_id][$eq]=${id}`)
}  

export const ReadLimitProfile = async (id, token) => {
    return await GET(`/profiles/${ id }`, token);
} 
