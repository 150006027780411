import React, { useContext, useEffect, useState } from "react";  
import { CoreContext } from "../../../context/CoreContext";
import { availableVideos } from "../../../services/mock";
import ButtonIcon from "../../ButtonIcon";

import {  
    FocusTrail, 
    
    ContentInfos,
    InfoSide,
    InfoSideTouch, 
    InfoTitle,
    InfoDescription,
    ButtonPlay,
    ButtonPlayIcon,
 

} from './styled'  

export default function OverlayInfos({ playPause, details }){   


    const { isFavorite, toggleFavorite } = useContext(CoreContext) 
    
    return ( 
        <>    
                <ContentInfos>
                    <InfoSide>
                        <InfoTitle>{  details?.title }</InfoTitle>
                        {/* <InfoDescription>{ details?.description }</InfoDescription>  */}
                        <InfoDescription /> 
                        <ButtonIcon focusKey={'button-banner-fav'} onPress={() => toggleFavorite(details)} onEnterPress={() => toggleFavorite(details)} icon={'heart'} spaced >
                            { isFavorite(details) ? `Remover dos favoritos` : `Adicionar aos favoritos`}
                        </ButtonIcon>
                    </InfoSide>
                    <InfoSideTouch>
                        <ButtonPlay focusKey={"play-button"} onEnterPress={playPause} onPress={playPause} >
                            <ButtonPlayIcon />
                        </ButtonPlay>
                    </InfoSideTouch>
                </ContentInfos>  
        </>
    )
} 
