import React, { useContext } from "react";  
import { CoreContext } from "../../../context/CoreContext"; 

import { 

    VideoHeaderContainer,
    VideoClose,
    VideoCloseIcon 

} from './styled'  

import VideoInformations from "../Information"; 
import { useNavigate } from "@reach/router";

export default function VideoHeader({ details, ...props }){  

    const { setCurrentVideo } = useContext(CoreContext)
    const navigate = useNavigate(props);

    const back = () => {
        navigate("/catalog")
        setCurrentVideo(null)
    }

    return ( 
        <>   
            <VideoHeaderContainer>
                <VideoClose onEnterPress={back} onPress={back}>
                    <VideoCloseIcon />
                </VideoClose>
                <VideoInformations details={details} /> 
            </VideoHeaderContainer> 
        </>
    )
} 
