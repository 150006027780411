import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';
  
export const FocusTrail = styled.TouchableHighlight.attrs(props => ({
    underlayColor: 'transparent'
}))`     
    flex:1;
`;  
export const ContentInfos = styled.View.attrs({
})`    
    flex-direction: row;
    padding: 0 ${getScaledValue(50)}px;  
    flex:1; 
`;     
export const InfoSide = styled.View.attrs({
})`    
    flex:1; 
`;     
export const InfoSideTouch = styled.View.attrs({
})`     
    flex:1; 
    align-items: center;
    justify-content: center; 
`;     
export const InfoTitle = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(28)}px;
    color: #ffffff;
    margin-top: ${ getScaledValue(10) }px;

`;     
export const InfoDescription = styled.Text.attrs({
    numberOfLines: 5
})`    
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(12)}px;
    color: #ffffff;
    margin-top: ${ getScaledValue(10) }px;
    margin-bottom: ${ getScaledValue(16) }px;
    max-width: 80%;
`;     
export const ButtonPlay = withFocusable()(styled.TouchableOpacity.attrs({
})`    
    width: ${getScaledValue(80)}px;
    height: ${getScaledValue(80)}px;
    border-radius:  ${getScaledValue(40)};
    background: ${ props => !props.focused ? `transparent` :  props.theme.primary || '#f95e14'  };
    align-items: center;
    justify-content: center;
    ${ props => !props.focused ? `
        border-width: ${getScaledValue(2)}px;
        border-color: #ffffff;
    ` :  `` }
`);     

export const ButtonPlayIcon = styled(Icons).attrs({
    icon: 'play',
    width: getScaledValue(26),
    height: getScaledValue(26),
    fill: "#ffffff"
})`    

`;      

 

 


  