import styled from 'styled-components/native'    

import Icons from './../../assets/icons' 
import { getScaledValue } from '../../ui/styled';
 
export const ProfileItemContent = styled.TouchableOpacity.attrs({
})`      
    margin: 0 ${ getScaledValue(14) }px;
    max-width: ${ getScaledValue(70) }px;
`;     

export const ProfileItemImage = styled.ImageBackground.attrs({
})`      
    width: ${ getScaledValue(70) }px;
    height: ${ getScaledValue(70) }px;
    border-radius: ${ getScaledValue(35) }px;
    overflow: hidden;
    ${
        props => props.focused ? `
            border-width: ${ getScaledValue(2) }px;
            border-color: #f95e14;
        ` : ``
    }
`;     

export const ProfileItemText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(12) }px;
    text-align: center; 
    font-family: PTSans-Bold;
    margin-top: ${ getScaledValue(11) }px;
    flex:1;
    ${
        props => props.focused ? `
            color: #f95e14;
        ` : `
            color: #A7A7A7;
        `
    }
`;     
 