import { GET, POST, PUT, DELETE } from './api'


export const ReadHome = async () => {
    return await GET(`/home?populate[0]=featured.featured_show&populate[1]=featured.featured_banner&populate[2]=icon`);
} 

export const ReadHomeTabs = async () => {
    return await GET(`/tabs?populate[0]=icon`);
} 

export const ReadTabPlaylist = async () => {
    return await GET(`/collections?populate[0]=stream_shows.thumbnail&populate[1]=icon`);
} 

export const ReadTabCatalog = async (tabId) => {
    return await GET(`/collections?filters[tab]=${ tabId }&populate[0]=stream_shows.thumbnail&populate[1]=icon`);
} 
    
export const ReadCatalogDetails = async (catalogId) => {
    return await GET(`/collections/${ catalogId }?populate[0]=stream_shows.thumbnail&populate[1]=icon`);
} 

export const ReadCategories = async () => {
    return await GET(`/categories`);
} 

export const ReadSearch = async (searchExpression) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=20&[populate][0]=thumbnail&where[name][$containsi]=${ searchExpression }`);
} 
 
export const ReadSearchByTheme = async (themeId) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=20&[populate][0]=thumbnail&where[themes]=${ themeId }`);
} 

export const ReadSearchByCharacter = async (charId) => {
    return await GET(`/stream-shows/?pagination[start]=1&[limit]=20&[populate][0]=thumbnail&where[characters]=${ charId }`);
} 



export const ReadKids = async () => {
    return await GET(`/collections?filters[name]=Kids&populate[0]=stream_shows`);
} 
    
export const ReadProgressPlayer = async (streamId, profileId, token) => {
    return await GET(`/progress-players?filters[profile]=${profileId}&filters[stream_show]=${streamId}&sort[0]=id:desc`, token);
} 
    
export const CreateProgressPlayer = async (body, token) => {
    return await POST(`/progress-players`, body, token);
} 

export const ReadKeepWatching = async (id, token) => {
    return await GET(`/progress-players?filters[profile]=${id}&populate=stream_show.thumbnail&sort[0]=createdAt:desc&sort[1]=updatedAt:desc&pagination[pageSize]=1000`, token);
} 
    
export const UpdateProgressPlayer = async (id, body, token) => {
    return await PUT(`/progress-players/${id}`, body, token);
} 



export const ReadShowDetails = async (showId, token) => {
    return await GET(`/stream-shows/${ showId }?[populate][0]=thumbnail&[populate][1]=wide_picture&[populate][2]=related_shows.thumbnail&[populate][3]=stream_master_file&[populate][4]=mobile_download_mp4&[populate][5]=next_stream`, token);
} 

export const ReadPreviewsShow = async (showId, token) => {
    return await GET(`/stream-shows?where[next_stream]=${ showId }&limit=1`, token);
}



export const ReadSerchableCharacteres = async () => {
    return await GET(`/characters`);
} 

export const ReadSerchableThemes = async () => {
    return await GET(`/themes`);
} 