import React, { useEffect } from 'react';
import { View } from 'react-native';
import { Router, navigate } from '@reach/router';

import ScreenHome from '../components/screenHome';
import ScreenMyPage from '../components/screenMyPage';
import ScreenModal from '../components/screenModal';

import Menu from '../components/menu';
import { themeStyles } from '../config';

import Home from '../project/screens/Home';
import SelectProfile from '../project/screens/SelectProfile';
import ConfirmExit from '../project/screens/ConfirmExit';
import AccessCode from '../project/screens/AccessCode';
import Catalog from '../project/screens/Catalog';
import VideoPlayer from '../project/components/VideoPlayer';
import Limit from '../project/screens/Limit';
import ModalCore from '../project/components/Modal';
import useOnline from '../project/hooks/useOnline';
import { getScaledValue } from '../project/ui/styled';

const App = () => {

    useOnline()

    useEffect(() => {
        // Required for tizen
        if (window.focus) window.focus();

        try{
            document.addEventListener("DOMContentLoaded", function() {
                let lazyImages = document.querySelectorAll('.lazy');
    
                let observer = new IntersectionObserver(function(entries, observer) {
                    entries.forEach(function(entry) {
                        if (entry.isIntersecting) {
                            let img = entry.target;
                            img.src = img.dataset.src;
                            img.classList.remove('lazy');
                            observer.unobserve(img);
                        }
                    });
                });
    
                lazyImages.forEach(function(img) {
                    observer.observe(img);
                });
            });
        }catch(e){}

        try{ 
            window.speechSynthesis = {
                speak: function() {
                    console.log('TTS is disabled');
                },
            };
        }catch(e){}

    }, []);

    return (
        <View style={themeStyles.app} aria-hidden="true">
            {/* <Menu focusKey="menu" navigate={navigate} /> */}
            <View style={themeStyles.appContainer}>
                <Router> 
                    
                    <Home path="/" /> 
                    <Limit path="/limit" />
                    <ConfirmExit path="/confirm-exit" />
                    <SelectProfile path="/select-profile" />
                    <VideoPlayer path="/video" />
                    <Catalog path="/catalog" />
                    <Catalog path="/favorites" />
                    <Catalog path="/search" /> 
                    <AccessCode path="/access-code" /> 


                    {/* <ScreenMyPage path="my-page" /> */}
                    {/* <ScreenModal path="modal" /> */}

                </Router>
            </View>
            <ModalCore />

            {/* <div style={{ position:'absolute', background:'white', top: 0, right: 0, height: `${ getScaledValue(10) }px`, width: `${ getScaledValue(40) }px` }}>
                { pressed?.join('\n') }
            </div> */}
        </View>
    );
};

export default App;
