import styled from 'styled-components/native'    
// import { getScaledValue } from 'renative'; 

import Icons from './../../assets/icons' 
import { getScaledValue } from '../../ui/styled';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const ContainerVideos = styled.View.attrs({
})`      
`;    
export const VideosTitle = styled.Text.attrs({
})`       
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Bold;
    color: #ffffff;
    margin-top: ${ getScaledValue(38) }px; 
    margin-bottom: ${ getScaledValue(10) }px; 
`;    
export const ContentVideos = styled.View.attrs({ 
})`     
    flex-direction: row;
`;     