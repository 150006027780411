export default [{
                              fontFamily: 'Inter-Light',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/appConfigs/base/fonts/Inter-Light.ttf'),
                          },{
                              fontFamily: 'PTSans-Bold',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/appConfigs/base/fonts/PTSans-Bold.ttf'),
                          },{
                              fontFamily: 'PTSans-BoldItalic',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/appConfigs/base/fonts/PTSans-BoldItalic.ttf'),
                          },{
                              fontFamily: 'PTSans-Italic',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/appConfigs/base/fonts/PTSans-Italic.ttf'),
                          },{
                              fontFamily: 'PTSans-Regular',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/appConfigs/base/fonts/PTSans-Regular.ttf'),
                          },{
                              fontFamily: 'AntDesign',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
                          },{
                              fontFamily: 'Entypo',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
                          },{
                              fontFamily: 'EvilIcons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/EvilIcons.ttf'),
                          },{
                              fontFamily: 'Feather',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
                          },{
                              fontFamily: 'FontAwesome',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Brands',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Brands.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Regular',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf'),
                          },{
                              fontFamily: 'FontAwesome5_Solid',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/FontAwesome5_Solid.ttf'),
                          },{
                              fontFamily: 'Fontisto',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Fontisto.ttf'),
                          },{
                              fontFamily: 'Foundation',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Foundation.ttf'),
                          },{
                              fontFamily: 'Ionicons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Ionicons.ttf'),
                          },{
                              fontFamily: 'MaterialCommunityIcons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
                          },{
                              fontFamily: 'MaterialIcons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
                          },{
                              fontFamily: 'Octicons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Octicons.ttf'),
                          },{
                              fontFamily: 'SimpleLineIcons',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/SimpleLineIcons.ttf'),
                          },{
                              fontFamily: 'Zocial',
                              file: require('/Users/israel/Desktop/x-apps/hoxxtv/hoxx-smart/hoxxtv-web/node_modules/react-native-vector-icons/Fonts/Zocial.ttf'),
                          },];