import { GET, POST, PUT, DELETE } from './api'
 
export const ReadMyHistory = async (profileId, token) => {
    return await GET(`/histories?profile=${ profileId }&populate[0]=stream_show.thumbnail&[limit]=9`, token);
}

export const AddHistory = async (profileId, params, token) => {
    return await POST(`/histories?profile=${ profileId }`, params, token);
}

export const RemoveHistory = async (profileId, token) => {
    return await DELETE(`/histories?profile=${ profileId }`, token);
}