import React, { useCallback, useContext, useRef, useState } from "react"; 

import {  

    ContainerVideos,
    VideosTitle,
    ContentVideos, 

} from './styled'  

import { availableVideos } from "../../services/mock";  
import VideoButton from "../VideoButton";
import { getScaledValue } from "../../ui/styled";
import { CoreContext } from "../../context/CoreContext";

export default function VideosRow({ title, handleVertical, videos }){      
    
    const sliderRef = useRef(null);
    const { setCurrentVideo } = useContext(CoreContext)

    const CARD_WIDTH = getScaledValue(127);
    const modulesMargin = getScaledValue(12);

    const rowVideos = videos ? videos : []

    const handleArrowPress = (direction, key) => {  
        const focusSlider = (nextSlide) => {
            if (sliderRef.current) {
                const value = (CARD_WIDTH + modulesMargin) * nextSlide;
                sliderRef.current.style.left = `-${value}px`;
            }
        };
    
        switch (direction) {
            case "right": {
            const nextSlide = Number(key) + 1;
                if (nextSlide > rowVideos.length - 1) return;
                return focusSlider(nextSlide);
            }
            case "left": {
            const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSlider(nextSlide);
            }
            default:
                if(handleVertical && typeof handleVertical === 'function' ){
                    handleVertical(direction, key)
                }
                return;
        };
        
    }

    return (
        <>  
            <ContainerVideos>
                { title ? <VideosTitle>{ title }</VideosTitle> : null }
                <ContentVideos ref={sliderRef} >
                    {
                        rowVideos.map((item, key) =>
                            <VideoButton key={key} item={item} spaced onArrowPress={(a,b) => handleArrowPress(a, key)} onEnterPress={() => setCurrentVideo(item)} onPress={() => setCurrentVideo(item)} />
                        )
                    }
                </ContentVideos>
            </ContainerVideos> 
        </>
    )
}