import React from "react"; 

import { 
    ProfileItemContent,
    ProfileItemImage,
    ProfileItemText
} from './styled' 

import { 
    withFocusable
} from "@noriginmedia/react-spatial-navigation";

export default withFocusable({   
})(function ProfileItem({ onPress, item, focused}){ 
    return (
        <> 
            <ProfileItemContent onPress={onPress}>
                <ProfileItemImage source={ item?.image } focused={focused} />
                <ProfileItemText focused={focused}>{ item?.name }</ProfileItemText>
            </ProfileItemContent>          
        </>
    )
});