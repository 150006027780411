import React, { useEffect, useState, useContext, useCallback } from "react";
import { useNavigate } from 'renative';

import {
    Container,
    ContentLogo,
    LogoIcon,
    LogoText,

    ContentWelcome,
    WelcomeTitle,
    WelcomeText,

    ContentAccess,
    PinInput,
    AccessButton,

    ContainerSideASide,
    ContentSideLogo,
    ContentSide,
 
    ContentMiddle,
    ContentKeyboard,
    KeyboardKey,
    KeyboardKeyText,
    AccessButtonText,
    LogoImage
} from './styled'

import { ActivityIndicator } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config';

// const FocusableView = hasWebFocusableUI ? withFocusable({})(View) : View;



import { DoLogin, ReadMe } from "../../services/authentication";
import { ReadProfiles } from "../../services/profile";
import { CoreContext } from "../../context/CoreContext";
import { exposeStrapiError } from "../../utils";
import { parseProfile } from "../../utils/parser";
import useRemoteControl from "../../hooks/useRemoteControl";


export default withFocusable({
    trackChildren: true,
    // forgetLastFocusedChild: false, 
})(function Home(props){
 

    const navigate = useNavigate(props);
    const [accessCode, setAccessCode] = useState([])
    const [logged, setLogged] = useState(false)

    const [cleaning, setCleaning] = useState(false)
    const [keyFocused, setKeyFocused] = useState(null)

    const addKey = item => {
        if( accessCode?.length < 5 ){
            setAccessCode([ ...accessCode, `${item}` ])
        }
    }

    const removeKey = () => {
        const keys = [ ...accessCode ]
        keys.pop()
        setAccessCode([...keys])
        setCleaning(true)
    }


    const handleRemote = useCallback( code => { 
        if(code === 10009 || code === 461 || code === 27 || code === 8 || code === 10182){ 
            try{
                window.tizen.application.getCurrentApplication().exit();
            }catch(er){
                try{
                    window.close()
                }catch(err){
                    console.log("cant")
                    navigate('/')
                }
            }
        }
    }, [])

   const { pressed } = useRemoteControl(handleRemote)

    // const next = () => {
    //     console.log('Access with code :: ', accessCode.join('') )
    //     navigate('select-profile')
    // }

    useEffect(() => {  
        console.log("Focus setting", props)
        props.setFocus('access-1')
        console.log("Focus setted")
     }, []);
    
     
     useEffect(() => {  
        console.log("Handle focus setting", props.realFocusKey )
     }, [ props.realFocusKey ]);
    
     useEffect(() => {  
        if(cleaning){ setCleaning(false) ;}
     }, [cleaning]);
    
     useEffect(() => {  
        if(accessCode?.length >= 5){
            props.setFocus('access-1');
        } 
     }, [accessCode]);



    const { setProfiles, setCurrentProfile, authentication, setAuthentication, setUser, setNext } = useContext(CoreContext)
    const [loading, setLoading] = useState(false) 

    const next = () => {
        console.log('Access with code :: ', accessCode.join('') )
        login()
    } 
 
    const valid = (verbose = false) => {  

        if(!accessCode || accessCode.length < 5){ 
            if(verbose){ alert('Informe o código de acesso, você pode obte-lo acessando o aplicativo') ;}
            return false; 
        }   

        return true
    } 

    const login = async () => {  
        if(!valid(true)){ return ;}
        setLoading(true)
        const result = await DoLogin({ code: accessCode.join('') })
        setLoading(false)
        if(result && !exposeStrapiError(result)){
            // completeLogin(result)
            if(result?.jwt){ 
                setAuthentication(result) 
            }else{
                alert("Código inválido")
                setAccessCode([])
                return;
            }   

        }
    }
 
    const completeLogin = async (result) => {
        if(!logged){
     
            if(result?.user){ setUser(result.user) } 
            
            const profiles = await ReadProfiles(result?.jwt)  
            const me = await ReadMe(result?.jwt)  
    
            if(me?.active_profile){
                setCurrentProfile(parseProfile(me?.active_profile))
            }
    
            if(profiles?.length){ 
                setLogged(true)
                setProfiles(profiles.map(parseProfile))
                setNext('/select-profile')
                navigate('confirm-exit')
            }else{
                navigate('catalog')
            }
        }
    }  

    useEffect(() => {
        if(authentication?.jwt){
            completeLogin(authentication)
        }
    }, [authentication])

    return props.path !== "/" ? null : (
        <>
            <Container>
                <ContainerSideASide> 
                    <ContentSide>

                        <ContentWelcome>
                            <LogoImage />
                            <WelcomeTitle aria-hidden="true">Olá, bem-vindo(a)!</WelcomeTitle>
                            <WelcomeText aria-hidden="true">Para entrar no HoxxTV, acesse o menu <WelcomeText bold>Perfil</WelcomeText> no aplicativo, depois aperte em <WelcomeText bold>Acesso a TV</WelcomeText> e digite o código gerado nos campos abaixo:</WelcomeText>
                        </ContentWelcome>
                        
                        <ContentMiddle>
                                <ContentAccess>
                                    { cleaning ? null : <PinInput onInputChange={setAccessCode} fields={accessCode} />}
                                </ContentAccess>
                        </ContentMiddle>
                        
                        <AccessButton active={accessCode?.length >= 5} onPress={next} onEnterPress={next} focusKey={'access-1'}>
                            {
                                loading ? <ActivityIndicator color={"white"} /> :
                                <AccessButtonText aria-hidden="true">
                                    Entrar
                                </AccessButtonText>
                            }
                        </AccessButton>

                        <ContentKeyboard>

                            {
                                [1,2,3,4,5,6,7,8,9,0].map((item, key) => 
                                    <KeyboardKey key={key} onPress={() => addKey(item)} onEnterPress={() => addKey(item)} focusKey={`home-${ key+1 }`}>
                                        <KeyboardKeyText>
                                            { item }
                                        </KeyboardKeyText>
                                    </KeyboardKey>
                                )
                            }
                            <KeyboardKey onPress={() => removeKey()} onEnterPress={() => removeKey()} focusKey={'remove-1'} >
                                <KeyboardKeyText aria-hidden="true">
                                    Apagar
                                </KeyboardKeyText>
                            </KeyboardKey>
                        </ContentKeyboard>  

                    </ContentSide>
                </ContainerSideASide> 

            </Container>
        </>
    )
})