import * as React from 'react';
import { Api } from 'renative';
// import {Svg} from 'react-native-svg';
 
  
// import { comum } from './comum';   
// import { project } from './project';   
 

// const icons = {   
//     ...comum,
//     ...project
// };

// export default (props) =>
//   props.icon && icons[props.icon] ? (
//     <Svg
//         viewBox={icons[props.icon].viewBox}
//         width={props.width ? props.width : '100%'}
//         height={props.height ? props.height : '100%'}
//         style={props.style}
//         fill={props.fill ? props.fill : ''}
//         stroke={props.stroke ? props.stroke : ''}
//         line={props.line ? props.line : ''}>
//         {icons[props.icon].svg}
//     </Svg>
//   ) : null;













import backspace from './svgs/backspace.svg'
import close from './svgs/close.svg'
import exit from './svgs/exit.svg'
import face from './svgs/face.svg'
import heart from './svgs/heart.svg'
import heartblack from './svgs/heartblack.svg'
import home from './svgs/home.svg'
import liked from './svgs/liked.svg'
import logo from './svgs/logo.svg'

import music from './svgs/music.svg'
import ops from './svgs/ops.svg'
import paint from './svgs/paint.svg'
import play from './svgs/play.svg'
import plus from './svgs/plus.svg'
import popcorn from './svgs/popcorn.svg'
import search from './svgs/search.svg'
import shift from './svgs/shift.svg'
import smile from './svgs/smile.svg'
import space from './svgs/space.svg'

import playblack from './svgs/playblack.svg'
import purpleheart from './svgs/purpleheart.svg'


export default (props) => {

  const allIcons = {
    backspace,
    close,
    face,
    heart,
    heartblack,
    home,
    liked,
    logo,
    music,
    ops,
    logo,
    music,
    ops,
    paint,
    play,
    plus,
    popcorn,
    search,
    shift,
    smile,
    space, 
    exit,


    playblack,
    purpleheart
  }



  return props.icon && allIcons[props.icon] && Api.engine === 'engine-rn-web' ?  (
    <> 
      <img src={ allIcons[props.icon] } 
          alt={props.icon}
          width={props.width ? props.width : '100%'}
          height={props.height ? props.height : '100%'}
        /> 
    </>
  ) : null
} 