import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 

// import {
//     getScaledValue
// } from 'renative'; 

export const Container = styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  

    background-color: #000000; 
    
    flex-direction: row;
    z-index:3;
`;     


export const ContentBody = styled.ScrollView.attrs({
    showsVerticalScrollIndicator:false
})`     
    background: #000000;
    flex:1;
`;     

 

export const BodyPrincipal = styled.View.attrs({
})`     
    padding: 0 0 ${ getScaledValue(20) }px; 
`;  



export const BodyMenu = styled.View.attrs({
})`     
    padding:  ${ getScaledValue(20) }px 0 0 ${ getScaledValue(20) }px; 
    flex-direction: row;
`;   


export const BodyContainer = styled.View.attrs({
})`     
    padding:  ${ getScaledValue(20) }px 0 0 ${ getScaledValue(20) }px; 
`;   





export const FavoriteContainer = styled.View.attrs({
})`      
    position: relative;
    align-items: center;
    justify-content: center;
 
    min-height: 100%;
`;    


export const FavoriteDecoration = styled(Icons).attrs({
    icon: 'heart',
    fill: '#000000',
    width: getScaledValue(465),
    height: getScaledValue(413)
})`      
    position: absolute;
`;    

export const FavoriteContent = styled.View.attrs({
})`      
    width: ${getScaledValue(80)}px;
    height: ${getScaledValue(80)}px;
    border-radius: ${getScaledValue(40)}px;
    background: ${ props => props.theme.primary || '#f95e14' };
    border-width: ${getScaledValue(3)}px;
    border-color: #f95e14;
    margin-bottom: ${getScaledValue(20)}px;
    align-items: center;
    justify-content: center;

`;    

export const FavoriteIcon = styled(Icons).attrs({
    icon: 'heart',
    fill: '#ffffff',
    width: getScaledValue(38),
    height: getScaledValue(33)
})`      
`;    

export const FavoriteText = styled.Text.attrs({
})`      
    font-size: ${getScaledValue(12)}px;
    color: #ffffff;
    font-family: PTSans-Regular;
    text-align: center;

    max-width: ${ getScaledValue(230) }px;
    margin: 0 auto;
`;    