import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 

// import {
//     getScaledValue
// } from 'renative';  


export const BodyMenuItemAction = styled.TouchableOpacity.attrs({
    underlayColor:'#f95e14'
})`      
`;  

export const BodyMenuItem = styled.View.attrs({
})`     
    flex-direction: row; 
    background: #444444;

    padding: ${ getScaledValue(3) }px;
    align-items: center;
    border-radius: ${ getScaledValue(13) }px;
    margin-right: ${ getScaledValue(12) }px;
    height: ${ getScaledValue(27) }px;
    ${
        p => p.active ? `
            border: ${getScaledValue(2)}px solid #f95e14;  
        ` : ``
    }
`;  


export const BodyMenuImageContent = styled.ImageBackground.attrs({
})`     
    width: ${ getScaledValue(22) }px;
    height: ${ getScaledValue(22) }px;
    border-radius: ${ getScaledValue(11) }px;
    background: #000000;

    align-items: center;
    justify-content: center;

    ${
        props => props.active ? `
            width: ${ getScaledValue(30) }px;
            height: ${ getScaledValue(30) }px;  
            border-radius: ${ getScaledValue(15) }px;  
            margin: -${ getScaledValue(6) }px 0 -${ getScaledValue(6) }px -${ getScaledValue(6) }px; 
        ` : ``
    } 
`;  

export const BodyMenuIconContent = styled.View.attrs({
})`     
    width: ${ getScaledValue(22) }px;
    height: ${ getScaledValue(22) }px;
    border-radius: ${ getScaledValue(11) }px;
    background: #000000;

    align-items: center;
    justify-content: center;

    ${
        props => props.active ? `
            width: ${ getScaledValue(30) }px;
            height: ${ getScaledValue(30) }px; 
            background: ${ props.theme.primary || '#f95e14' };
            border-radius: ${ getScaledValue(15) }px; 
            border-width: ${ getScaledValue(3) }px; 
            border-color: #f95e14;  
            margin: -${ getScaledValue(6) }px 0 -${ getScaledValue(6) }px -${ getScaledValue(3) }px; 


        ` : ``
    } 
`;  
export const BodyMenuIcon = styled(Icons).attrs({
})`     
`;  
export const BodyMenuText = styled.Text.attrs({
})`     
    margin: 0 ${ getScaledValue(8) }px;
    font-size:  ${ getScaledValue(12) }px; 
    color: #ffffff;
    font-family: PTSans-Bold;
`;  