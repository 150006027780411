import React, { useContext, useRef } from "react";  
import { CoreContext } from "../../../context/CoreContext";
import { availableVideos } from "../../../services/mock"; 
import { getScaledValue } from "../../../ui/styled";

import {  

    RecommendationContainer,
    RecommendationTitle,
    RecommendationContent,
    RecommendationVideo,
    RecommendationVideoImage 

} from './styled'   

export default function VideoRecommendations({ relateds }){  


    const sliderRef = useRef(null);
    const { setCurrentVideo } = useContext(CoreContext)

    const CARD_WIDTH = getScaledValue(93);
    const modulesMargin = getScaledValue(12);

    const handleArrowPress = (direction, key) => {  
        
        const focusSlider = (nextSlide) => {
            if (sliderRef.current) {
                const value = (CARD_WIDTH + modulesMargin) * nextSlide;
                sliderRef.current.style.left = `-${value}px`;
            }
        };
    
        switch (direction) {
            case "right": {
            const nextSlide = Number(key) + 1;
                if (nextSlide > availableVideos.length - 1) return;
                return focusSlider(nextSlide);
            }
            case "left": {
            const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSlider(nextSlide);
            }
            default:
                return;
        };
        
    }

    return ( 
        <>   
            <RecommendationContainer>
                <RecommendationTitle>Relacionados</RecommendationTitle>
                <RecommendationContent ref={sliderRef} >
                    {
                        relateds.map((item, key) => 
                            <RecommendationVideo key={key} onArrowPress={(a,b) => handleArrowPress(a, key)}  onEnterPress={() => setCurrentVideo(item)} onPress={() => setCurrentVideo(item)} >
                                <RecommendationVideoImage source={item.cover} />
                            </RecommendationVideo>
                        )
                    }
                </RecommendationContent>
            </RecommendationContainer>  
        </>
    )
} 
