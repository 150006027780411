import { GET, POST, PUT, DELETE } from './api'

export const ReadFavorites = async (profileId, token) => {
    return await GET(`/favorites?profile=${ profileId }`, token);
}  

export const AddFavorite = async (profileId, params, token) => {
    return await POST(`/favorites?profile=${ profileId }`, params, token);
}  

export const RemoveFavorite = async (profileId, id, token) => {
    return await DELETE(`/favorites/${ id }?profile=${ profileId }`, token);
}  