import React from "react"; 

import {  
    BannerButton,
    BannerButtonIcon,
    BannerButtonText, 
} from './styled' 

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export default withFocusable({
})(function ButtonIcon({ onPress, focused, children, icon, spaced }){ 

    return (
        <>  
            <BannerButton onPress={onPress} active={ focused } spaced={spaced} >
                <>
                    <BannerButtonIcon active={ focused } icon={icon} />
                    <BannerButtonText active={ focused } >{ children }</BannerButtonText>
                </>
            </BannerButton>
        </>
    )
})