import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';

import { Button } from 'renative';
// import {
//     getScaledValue
// } from 'renative'; 

export const Container = styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  

    background-color: #000000; 

    justify-content: center;
    align-items: center;

    padding-bottom: ${ getScaledValue(100) }px;
`;     

export const ContentLogo = styled.View.attrs({
})`    
    flex-direction: row;
    align-items: center; 
    marginBottom: ${ getScaledValue(30) }px;
`;     

export const LogoIcon = styled(Icons).attrs({
    icon: 'logo',
    width: getScaledValue(103),
    height: getScaledValue(100) 
})`      
    margin-right: -${ getScaledValue(12) }px;
`;     

export const LogoText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(30) }px;
    font-weight: 300;
    color: #ffffff;
    margin-top: -${ getScaledValue(12) }px;
`;     




export const ContentWelcome = styled.View.attrs({
})`     
    max-width: ${ getScaledValue(300) }px;
    margin-bottom: 20px;
`;     

export const WelcomeTitle = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(24) }px;
    text-align: center; 
    color: #ffffff;
    font-family: PTSans-Bold;
    margin-bottom: ${ getScaledValue(12) }px;
`;     

export const WelcomeText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(12) }px; 
    color: #ffffff;
    margin-bottom: ${ getScaledValue(4) }px;
    text-align: center;
    font-family: PTSans-Regular;
    ${
        props => props.bold ? `
            font-family: PTSans-Bold;
        ` : `
        `
    }
`;     

export const ContentAccess = styled.Text.attrs({
})`      
    max-height: ${ getScaledValue(60) }px;
`;     


import PinInputEntry from "../../components/Pin";

export const PinInput = styled(PinInputEntry).attrs({
    placeholder:' ',
    digits: 5, 
})`    
    background-color: rgba(255,255,255,.2);
    borderRadius: ${ getScaledValue(3) }px;
    margin:  ${ getScaledValue(5) }px;
    width:  ${ getScaledValue(44) }px;
    height:  ${ getScaledValue(56) }px;
    border-width: .5px;
    font-family: Inter-Light;
    font-size:  ${ getScaledValue(16) }px;
    text-align: center;
    border-color:  rgba(255,255,255,.3);
    color:  #ffffff;
`; 
   
 

export const AccessButton = styled(Button).attrs({
    textStyle:{
        fontFamily: 'Inter-Light',
        color: '#ffffff',
        fontSize: getScaledValue(16)
    }
})`     
    marginHorizontal: ${ getScaledValue(20) }px;
    borderRadius: ${ getScaledValue(6) }px;
    height: ${ getScaledValue(40) }px;
    min-width: ${ getScaledValue(150) }px; 
    marginTop: ${ getScaledValue(20) }px;
    ${
        props => props.active ? `
            background: #f95e14;
        ` : `
            backgroundColor: #999999;
        `
    }

`; 
   
 
export const LogoImage = styled.ImageBackground.attrs({
    source: require('./../../assets/logo.png'),
    imageStyle:{
        resizeMode: "contain"
    },
})`     
    margin: 0 auto ${ getScaledValue(10) }px;
    width: ${ getScaledValue(160) }px
    height: ${ getScaledValue(60) }px;
`;     