import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 

// import {
//     getScaledValue
// } from 'renative'; 
 
export const ContentSide = styled.View.attrs({
})`    
    padding:  ${ getScaledValue(15) }px ${ getScaledValue(12) }px ${ getScaledValue(50) }px ${ getScaledValue(12) }px;
    background: rgba(255,255,255, .1);

    justify-content: space-between;
    align-items: center;
`;     
 
export const LogoOut = styled.View.attrs({
})`     
`;     

export const LogoIcon = styled(Icons).attrs({
    icon: 'logo',
    width: getScaledValue(50),
    height: getScaledValue(50) 
})`    
    margin-right: -${ getScaledValue(9) }px; 
    margin-left: -${ getScaledValue(10) }px; 
`;     
 


export const SideOptions = styled.View.attrs({
})`    
    align-items: center;
    justify-content: space-between;
    background: rgba(255,255,255,.1);
    width: ${ getScaledValue(34) }px;
    height: ${ getScaledValue(132) }px;
    padding: ${ getScaledValue(5) }px  ${ getScaledValue(4) }px;

    border-radius: ${ getScaledValue(30) }px;
`;      