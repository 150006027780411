import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from 'renative';

import {
    Container,
    ContentLogo,
    LogoIcon,
    LogoText,

    ContentWelcome,
    WelcomeTitle,
    WelcomeText,

    ContentProfiles,
    LogoImage,
    AccessButton,
    AccessButtonText,
    CenterLoad, Load
} from './styled'

import { View } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config';
import { CoreContext } from "../../context/CoreContext";

import { ReadLimitProfile, ReadMultiScreen } from "../../services/authentication"; 
import { normalizeStrapiList, normalizeStrapiRegister } from "../../utils"; 
import useRemoteControl from "../../hooks/useRemoteControl";

// const FocusableView = hasWebFocusableUI ? withFocusable()(View) : View;

export default withFocusable({
    trackChildren: true,
    forgetLastFocusedChild: false, 
})(function Limit(props){

    const navigate = useNavigate(props);
    const { next, setNext, currentProfile, user, authentication, currentVideo, setCurrentVideo } = useContext(CoreContext)

    const handleRemote = useCallback( code => { 
        if( code === 10009 || code === 461 || code === 27 || code === 8 || code === 10182 ){ 
            cancel()
        }
    }, [])

   const { pressed } = useRemoteControl(handleRemote)
  
    const cancel = () => { 
        setCurrentVideo(null)
        navigate(currentProfile ? '/catalog' : '/select-profile')
    }

    const retry = () => { 
        // setTimeout(() => { setCurrentVideo({ ...currentVideo, ts:new Date().getTime() }) ;}, 300)
        navigate('/catalog')
    }

    useEffect(() => {  
        props.setFocus('access-1')
        if(next){
            setNext(null)
            navigate(next)
        }
    }, []);
 

    const [ screens, setScreens ] = useState([])
    const [ loading, setLoading ] = useState(false) 
    
    const init = async () => {
        setLoading(true)
        const result = await ReadMultiScreen(user?.id)

        if(result?.data?.length){
            const normalResult = normalizeStrapiList(result)
    
            const promises = normalResult.map(async item => {
                const profile = item?.profile_id ? await ReadLimitProfile(item?.profile_id, authentication?.jwt) : null
                const stream_show = normalizeStrapiRegister(item?.stream_show)
                return {
                    ...item,
                    watching: stream_show?.name,
                    profile: profile?.name || "",
                    device: item.device
                }
            })
    
            const watchers = await Promise.all(promises)
            setScreens(watchers) 
        }

        setLoading(false)
    }

    useEffect(() => {
        init()
    }, [])


    
    return props.path !== "/limit" ? null : (
        <>
            <Container> 

                <LogoImage />
                {
                    next ? null : 
                    <ContentWelcome>
                        <WelcomeTitle>Limite de telas</WelcomeTitle>
                        <WelcomeText>Muitas pessoas estão usando sua conta no momento. Para continuar assistindo troque de plano ou feche uma destas telas</WelcomeText>
                        

                        {
                            loading ? <CenterLoad><Load /></CenterLoad>
                            : screens?.map((item, key) =>
                                <WelcomeText key={key}> { item?.device } ({ item?.profile })<WelcomeText light> - { item?.watching }</WelcomeText></WelcomeText>
                            )
                        }

                        <ContentProfiles>
                            
                            <AccessButton onPress={cancel} onEnterPress={cancel} focusKey={'access-1'}> 
                                <AccessButtonText>
                                    Cancelar
                                </AccessButtonText>
                            </AccessButton>
                            <AccessButton active onPress={retry} onEnterPress={retry} focusKey={'access-2'}> 
                                <AccessButtonText>
                                    Tentar Novamente
                                </AccessButtonText>
                            </AccessButton>

                        </ContentProfiles>
                    </ContentWelcome> 
                }

            </Container>
        </>
    )
});