import React, { useEffect, useContext, useState } from 'react';

import { ThemeProvider } from 'styled-components'
  
import { Blue, Pink } from '../ui/colors';
import { CoreContext } from './CoreContext';

export function ThemeContext({ children }){
 
    const { currentProfile } = useContext(CoreContext)
    const [currentTheme, setCurrentTheme] = useState(Pink)

    useEffect(() => { 
        if(currentProfile){
            setCurrentTheme( currentProfile.theme === 'blue' ? Blue : Pink )
        }
    }, [currentProfile])

    return (
        <> 
            <ThemeProvider theme={currentTheme}>
                { children }
            </ThemeProvider> 
        </>
    )
} 