import styled from 'styled-components/native'    
import { Api, getScaledValue } from 'renative';  

export const Container = styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  


    background-color: #000000; 
    
    display: flex;
    flex-direction: row;
    z-index:3;
`;     

export const ContentBody = styled.View.attrs({
    showsVerticalScrollIndicator:false
})`     
    background: #000000; 
`;     

export const BodyPrincipal = styled.View.attrs({
})`       
    margin: ${ getScaledValue(18) }px ${ getScaledValue(21) }px; 
    ${
        props => props.wrapped ? `
            flex-direction: row;
        ` : ``
    } 
`;  

export const WrapInput = styled.View.attrs({
})`         
    background: rgba(255,255,255,.1);
    padding: 0 0 ${ getScaledValue(12) }px ${ getScaledValue(12) }px;
    border-top-left-radius: ${ getScaledValue(30) }px;
    border-top-right-radius: ${ getScaledValue(30) }px;
    border-bottom-left-radius: ${ getScaledValue(6) }px;
    border-bottom-right-radius: ${ getScaledValue(6) }px;
    ${
        Api.engine === 'engine-rn-web' ? `
            display: table;
        ` : ``
    }
`;

export const WrapRec = styled.View.attrs({
})`       
    display: flex; 
    max-width: 100%; 
    overflow: hidden;
`;

export const ContainerLoad = styled.View.attrs({ 
})`   
    height: 100vh;
    width: 100vw;
    
            background: #000000; 
            display: flex;
            align-items: center;
            justify-content: center;
            
`;  