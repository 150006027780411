import styled from 'styled-components/native'    
import { getScaledValue, Api } from 'renative'; 
import Icons from './../../assets/icons'
import { View } from 'react-native';

// import LinearGradient from 'react-native-linear-gradient'
// import Video from 'react-native-video';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const Container = withFocusable({
    trackChildren: true
})(styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  


    background: #000000; 
    font-family: PTSans-Regular;
    flex-direction: row;
    z-index:3;
    overflow: hidden;
`);      

export const ContainerScroll = styled.ScrollView.attrs({
    showsVerticalScrollIndicator:false
})`          
`;     

export const VideoTouch = withFocusable()(styled.View.attrs({ 
})`   
    width: 100%;
    height: 100%;
`);     

// export const VideoVideo = styled(Video).attrs({ 
//     source: { uri:'https://d23dyxeqlo5psv.cloudfront.net/big_buck_bunny.mp4' },
//     controls: true
// })`   
//     width: 100%;
//     height: 100%;
// `;     

export const VideoVideo = styled.ImageBackground.attrs({ 
    source: require('./../../assets/videos/video-1.png')
})`   
    width: 100%;
    height: 100%;
`;     

export const ContainerVideo = styled.View.attrs({ 
})`   
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    ${
        props => props.wrapped ? `
            flex-direction: row;
        ` : ``
    }
    ${
        props => props.purple ? `
            background: #000000; 
            display: flex;
            align-items: center;
            justify-content: center;
        ` : ``
    }
    overflow: hidden;
`;     

export const VideoDegrade = styled.View.attrs({ 
})`
    background-image: linear-gradient(to right, rgba(0,0,0,.9) , rgba(0,0,0, 0.1) ); 
    flex:1; 
    width: 100%;
`;     

// export const VideoDegrade = styled(LinearGradient).attrs({
//     colors:['#000000', 'transparent'],
//     start: { x: 0.1, y: 0
//      },
//     end: { x: 0.2, y: 0.7 },
// })`
//     background: rgba(53,36,93,.3);
//     flex:1; 
//     width: 100%;
// `;     

export const VideoContainer = styled.View.attrs({
})`    
    padding: ${getScaledValue(24)}px 0 0;
    background: rgba(53,36,93,.3);
    flex:1; 
`;     
 
export const VideoContainerPadding = styled.View.attrs({
})`    

    padding: 0 ${getScaledValue(24)}px;
    flex:1;
`;     
 