import React, { useContext, useState } from "react";
import { CoreContext } from "../../../context/CoreContext";

import {
    UserProfileContent,
    UserProfile, 
} from './styled' 
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export default withFocusable({
})(function SideUserButton({ onPress, focused }){ 

    const { currentProfile } = useContext(CoreContext)

    return (
        <>
            <UserProfileContent onPress={onPress}>
                <UserProfile source={currentProfile?.image} focused={focused} />
            </UserProfileContent>
        </>
    )
})