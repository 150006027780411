import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

 

export const ModalHeaderContainer = styled.View.attrs({
})` 
    padding: ${ getScaledValue(10) }px;
    background: #f95e14;
    width: 100%;

    max-width: ${ getScaledValue(225) }px;

    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
`;
export const ModalHeaderIcon = styled(Icons).attrs({ 
})` 
`;
export const ModalHeaderClose = withFocusable()(styled.TouchableOpacity.attrs({
})` 
    width: ${getScaledValue(18)}px;
    height: ${getScaledValue(18)}px;
    border-radius: ${getScaledValue(9)}px;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : `
            background: rgba(255,255,255,.2);
        `
    }
    align-items: center;
    justify-content: center;
`);
export const ModalHeaderCloseIcon = styled(Icons).attrs({
    icon: 'close',
    stroke: '#fff',
    width: getScaledValue(6),
    height: getScaledValue(6),
})` 
`;
 
export const ModalHeaderSpace = styled.View.attrs({
})` 
width: ${getScaledValue(18)}px;
`; 



export const ModalContainer = styled.View.attrs({
})` 
    max-width: ${ getScaledValue(225) }px;
`; 
 



export const ModalBody = styled.View.attrs({
})` 
    padding: ${getScaledValue(18)}px;
    background: #ffffff;

    max-width: ${ getScaledValue(225) }px;
    width: 100%;

`; 
 



export const ModalTitle = styled.Text.attrs({
})`  
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(16) }px;
    color: #f95e14;
    text-align: center;
`; 
export const ModalText = styled.Text.attrs({
})`   
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12) }px;
    color: #000000;
    text-align: center;
    padding: ${ getScaledValue(8) }px ${ getScaledValue(8) }px ${ getScaledValue(16) }px;
`; 
export const ModalAction = withFocusable()(styled.TouchableOpacity.attrs({
})`  
    padding: ${ getScaledValue(8) }px;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : `
            background: #f95e14;
        `
    }
    border-radius: ${getScaledValue(5)}px;
`); 

export const ModalActionText = styled.Text.attrs({
})`  
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(12) }px;
    color: #fff;
    text-align: center;
`; 
 