import styled from 'styled-components/native'    

import Icons from './../../assets/icons' 
import { getScaledValue } from '../../ui/styled';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';
// import { getScaledValue } from 'renative'; 
 

export const ContentVideosCategory = styled.View.attrs({
})`     
    padding-top:  ${ getScaledValue(20) }px; 
`;  
export const VideosCategoryTitle = styled.Text.attrs({
})`     
    font-size:  ${ getScaledValue(14) }px; 
    color: #ffffff;
    font-family: PTSans-Bold;
    margin: 0 ${ getScaledValue(20) }px ${ getScaledValue(8) }px;
`;  
export const VideosCategoryScroll = styled.View.attrs({
    // horizontal: true,
    // contentContainerStyle:{
    //     paddingRight: getScaledValue(20)
    // },
    // showsHorizontalScrollIndicator:false
})`     
    flex-direction: row;
    padding-right: ${ getScaledValue(20) }px;
    background: red;
    oveflow: hidden;
`;  
export const VideosCategoryContent = styled.View.attrs({
})`     
    flex-direction: row; 
    align-items: center;
`;  
export const VideoCategoryIconContent = styled.TouchableOpacity.attrs({
})`     
    width: ${ getScaledValue(44) }px; 
    height: ${ getScaledValue(44) }px; 
    border-radius: ${ getScaledValue(22) }px; 
    background-color: #ffffff;
    margin: 0 ${ getScaledValue(40) }px; 

    align-items: center;
    justify-content: center;
`;  
export const VideoCategoryIcon = styled(Icons).attrs({
})`     
`;   



export const VideoCategoryImageContent = styled.TouchableOpacity.attrs({
})`      
    margin: 0 ${ getScaledValue(40) }px;  
    align-items: center;
    justify-content: center;
`;  
export const VideoCategoryImage = styled.ImageBackground.attrs({
})`      
    width: ${ getScaledValue(44) }px; 
    height: ${ getScaledValue(44) }px; 
    border-radius: ${ getScaledValue(22) }px; 
    background-color: #ffffff; 
    overflow: hidden;
`;   