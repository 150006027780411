import React, { useCallback, useContext, useRef, useState } from "react";

import { 

    ContentVideosCategory,
    VideosCategoryTitle,
    VideosCategoryScroll,
    VideosCategoryContent,
    VideoCategoryIconContent,
    VideoCategoryIcon,

    VideoCategoryImageContent,
    VideoCategoryImage

} from './styled' 

import VideoButton from "../VideoButton";
import { getScaledValue } from "../../ui/styled";
import { CoreContext } from "../../context/CoreContext";
 

export default function VideoCategory({ item, availableVideos, availableCatagories, verticalRef, verticalKey, verticalSize, verticalLoadMore }){ 

    const sliderRef = useRef(null);
    const { setCurrentVideo } = useContext(CoreContext)

    const CARD_WIDTH = getScaledValue(127);
    const modulesMargin = getScaledValue(12);

    const categoryVideos = availableVideos?.filter(fit => fit.category_id === item.id)


    const pageSize = 8;
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    let timerMore = useRef().current
    let timerMoreAction = useRef().current

    const handleArrowPress = (direction, key) => {   

        console.log("SLID IN", direction, key)

        const focusSlider = (nextSlide) => {
            if (sliderRef.current) { 
                const value = (CARD_WIDTH + modulesMargin) * nextSlide;
                sliderRef.current.style.left = `-${value}px`;
            }
        };

        const focusSliderVertical = (nextSlide) => {
            if (verticalRef.current) {
                const value = getScaledValue(148) * nextSlide;
                verticalRef.current.style.top = `-${value}px`;
            }
        };

        switch (direction) {
            case "right": {
                const nextSlide = Number(key) ;
                if ((nextSlide + 1) >= (page * pageSize) ){ loadMore(); }
                if (nextSlide > item?.videos?.length ) return;
                return focusSlider(nextSlide);
            }
            case "left": {
                const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSlider(nextSlide);
            }
            case "down": {
                const nextSlide = Number(verticalKey) + 1;
                if ((nextSlide + 1) >= verticalSize ){ verticalLoadMore(); }
                if (nextSlide > availableCatagories.length ) return;
                return focusSliderVertical(nextSlide);
            }
            case "up": {
                const nextSlide = Number(verticalKey) - 1;
                if (nextSlide < 0) return;
                return focusSliderVertical(nextSlide);
            }
            default:
                return;
        };
    }

    const loadMore = () => { 
        if(  (item?.videos?.length >= ( pageSize*(page) ) ) ){
            clearTimeout(timerMoreAction)
            timerMoreAction = setTimeout(() => {
                clearTimeout(timerMore)
                setLoading(true)
                console.log("Handle More")
                timerMore = setTimeout(() => {
                    setPage( page + 1 )
                    setLoading(false)
                }, 5000)
            }, 1500)
        }
    }   
    // console.log(item)

    return (
        <> 
            <ContentVideosCategory>
                <VideosCategoryTitle>{ item.title }</VideosCategoryTitle>
           
                    <VideosCategoryContent  ref={sliderRef} >
                            {
                                !item.image ? <>
                                        {
                                            !item.icon ? null :
                                            <VideoCategoryIconContent>
                                                <VideoCategoryIcon {...item.icon} />
                                            </VideoCategoryIconContent>
                                        }
                                </> :
                                <VideoCategoryImageContent>
                                    <VideoCategoryImage source={item.image} />
                                </VideoCategoryImageContent>
                            }

                            {
                                item?.videos?.slice(0, (page * pageSize))?.map((mit, key) => 
                                    <VideoButton key={`${ key }`} item={mit} onArrowPress={(a,b) => handleArrowPress(a,key)} onEnterPress={() => setCurrentVideo(mit)} onPress={() => setCurrentVideo(mit)} spaced />
                                )
                            }

                            { loading ? <VideoButton loading /> : null }
                    </VideosCategoryContent>
                
            </ContentVideosCategory>
        </>
    )
}