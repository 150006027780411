import styled from 'styled-components/native'     

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const Container = withFocusable({
    trackChildren: true
})(styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  


    background: #000000; 
    font-family: PTSans-Regular;
    flex-direction: row;
    z-index:3;
    overflow: hidden;
`);      