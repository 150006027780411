import React, { useEffect, useRef, useState } from "react"; 

import {  
    ContainerProfiles,
    ProfilesTitle,
    ContentProfiles,
    ProfileItem,
    ProfileItemImage, 

} from './styled'  
 
import { getScaledValue } from "../../../ui/styled";

export default function SearchCharacteres({ handleVertical, users, searchBy }){  
    
    const sliderRef = useRef(null);

    const CARD_WIDTH = getScaledValue(62);
    const modulesMargin = getScaledValue(0);

    const handleArrowPress = (direction, key) => {  
        console.log('SLID', direction, key)

        const focusSlider = (nextSlide) => {
            if (sliderRef.current) {
                const value = (CARD_WIDTH + modulesMargin) * nextSlide;
                sliderRef.current.style.left = `-${value}px`;
            }
        };
    
        switch (direction) {
            case "right": {
            const nextSlide = Number(key) + 1;
                if (nextSlide > users.length - 1) return;
                return focusSlider(nextSlide);
            }
            case "left": {
            const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSlider(nextSlide);
            }
            default:
                if(handleVertical && typeof handleVertical === 'function' ){
                    handleVertical(direction, key)
                }
                return;
        };
        
    } 
    
    return (
        <>                
            <ContainerProfiles>
                <ProfilesTitle>Buscar por personagens</ProfilesTitle>
                <ContentProfiles ref={ sliderRef }>
                    {
                        users.map((item, key) =>
                            <ProfileItem key={key} onArrowPress={(a,b) => handleArrowPress(a,key)} onPress={() => searchBy('charactere', item)} onEnterPress={() => searchBy('charactere', item)}>
                                <ProfileItemImage source={item.image} />
                            </ProfileItem>
                        )
                    }
                </ContentProfiles>
            </ContainerProfiles> 
        </> 
    )
}