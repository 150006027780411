import styled from 'styled-components/native'    

import Icons from './../../assets/icons' 
import { hexToRgb } from './../../ui/styled'

// import { getScaledValue } from 'renative'; 

import { getScaledValue } from '../../ui/styled';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const SideMoreContainer = styled.View.attrs({
})`    
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: ${ getScaledValue(240) }px;
    background: rgba(36,36,36, .9);
    padding: ${ getScaledValue(24) }px;
`;     

export const SideMoreClose = withFocusable()(styled.TouchableOpacity.attrs({ 
})`    
    width: ${ getScaledValue(18) }px;
    height: ${ getScaledValue(18) }px;
    border-radius: ${ getScaledValue(9) }px;
    background: rgba(255,255,255,.14);
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : ``
    }
    align-items: center;
    justify-content: center;
    align-self: flex-end;
`);     

export const SideMoreCloseIcon = styled(Icons).attrs({
        icon: 'close',
        width: getScaledValue(6),
        height: getScaledValue(6),
        stroke: '#ffffff'
})`    
`;     

export const SideMoreHeader = styled.View.attrs({
})`    
    margin-bottom: ${ getScaledValue(25) }px;

`;     
export const SideMoreHeaderTitle = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(24) }px;
    color: #ffffff;
`;     
export const SideMoreHeaderText = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12) }px;
    color: #ffffff;
`;     

export const SideMoreItem = withFocusable()(styled.TouchableOpacity.attrs(props=> ({ 
}))` 
    padding: ${ getScaledValue(10) }px;
    border-radius: ${getScaledValue(3)}px;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14'  };
            border-width: ${ getScaledValue(2) }px;
            border-color: #ffffff;
            box-shadow: 0px 0px 14px rgba(${ hexToRgb( props.theme.primary || '#f95e14' ) }, .9);
        ` : `
            background: #222222;
        `
    }
    margin-bottom: ${ getScaledValue(12) }px;
`);     

export const SideMoreItemText = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12) }px;
    color: #ffffff;
`;     
