import React, { useContext, useState } from "react";  

import {  
    SideItem,
    SideItemImage,
    SideText,
} from './styled'  

export default function VideoSide({ item }){ 
    const [focus, setFocus ] = useState(false)

    return ( 
        <>   
            <SideItem  active={focus} onFocus={() => setFocus(true)} onBlur={() => setFocus(false)}>
                <>
                    <SideItemImage active={focus} source={item.cover} />
                    <SideText>{ item.title }</SideText>
                </>
            </SideItem> 
        </>
    )
} 
