import React, { useEffect, useState } from "react"; 

import {  
 
    ContentInput,
    InputIcon,
    InputInput,
    InputRemove,
    InputRemoveIcon,

    ContainerSearchInput, 
 
    ContentHistory,
    HistoryTitle,
    HistoryText, 

    WrapInput, 
    HistoryAction

} from './styled'  

import Keyboard from "../../Keyboard"; 

export default function SearchInput({ wrapped, onSearch, searched, lastSearch }){ 
    
    const [search, setSearch] = useState('')
    
    useEffect(() => {
        if(searched && searched?.length){
            setSearch(searched)
        }
    }, [searched])

    return (
        <>  
            <WrapInput> 
                <ContentInput>
                    <InputIcon />
                    <InputInput placeholder={'O que você quer assistir?'} value={search} />
                    <InputRemove onPress={() => onSearch('')} onEnterPress={() => onSearch('')}>
                        <InputRemoveIcon />
                    </InputRemove>
                </ContentInput>

                <ContainerSearchInput wrapped={wrapped}>
                    <Keyboard onChangeText={setSearch} onSearch={() => onSearch(search)} clear={() => onSearch('')} />
                    <ContentHistory wrapped={wrapped}>
                        <HistoryTitle>Histórico de Pesquisa</HistoryTitle>
                        {
                            !lastSearch?.length ? <HistoryText>Nenhuma pesquisa recente.</HistoryText> :
                            lastSearch?.map((item, key) => 
                                <HistoryAction key={key} onPress={() => onSearch(item.term)} onEnterPress={() => onSearch(item.term)}>
                                    <HistoryText active>{ item.term }</HistoryText>
                                </HistoryAction>
                            )
                        }
                    </ContentHistory> 
                </ContainerSearchInput>
            </WrapInput>
        </>
    )
}