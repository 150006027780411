import styled from 'styled-components/native'     

// import { getScaledValue } from 'renative'; 
import { getScaledValue } from '../../../ui/styled';

 
export const ContentSideMenu = styled.View.attrs({
})`          
    background: rgba(0,0,0,.2);
    align-items: flex-end; 
    max-width:  ${ getScaledValue(320) }px;

`;      

export const ContentSideSide = styled.ScrollView.attrs({
    showsVerticalScrollIndicator:false
})`      
    padding: ${ getScaledValue(24)}px ${ getScaledValue(25)}px;
    background: #f95e14;
`;     

export const ContentSideTitle = styled.Text.attrs({
})`      
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(24)}px;
    padding: 0 ${ getScaledValue(12)}px;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(21)}px;
`;     
