import React, { useState } from "react"; 

import { 
    SideOption,
    SideOptionIcon, 
} from './styled' 

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export default withFocusable({
})(function SideButton({ item, onPress, active, first, last, focused }){  
    return (
        <> 
            <SideOption onPress={onPress} active={focused || active} first={first} last={last} >
                <SideOptionIcon { ...item.icon } active={focused} />
            </SideOption>
        </>
    )
});