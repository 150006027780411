import { withFocusable } from '@noriginmedia/react-spatial-navigation'
import React, { useContext, useEffect, useState } from 'react'
import { CoreContext } from '../../context/CoreContext'
import { parseCategory } from '../../utils/parser'


import {
    SideMoreContainer,
    SideMoreClose,
    SideMoreCloseIcon,
    SideMoreHeader,
    SideMoreHeaderTitle,
    SideMoreHeaderText,
    SideMoreItem,
    SideMoreItemText,
} from './styled'

export default withFocusable({ 
    trackChildren: true, 
    forgetLastFocusedChild: false, 
})(function SideMore(props){


    const { side, setSide, setMoreCategory } = useContext(CoreContext)
    
    const [ active, setActive] = useState(0)
    
    const options =  side?.tabs?.length  > 3 ? [...side?.tabs].slice(3).map(parseCategory) : []

    const action = item => {
        setSide(null)
        setMoreCategory(item)
    } 

    return side?.type !== 'side-more' ? null : (
        <>
            <SideMoreContainer>
                <SideMoreClose onPress={() => setSide(null)} onEnterPress={() => setSide(null)} focusKey={`sidemore-close`} >
                    <SideMoreCloseIcon />
                </SideMoreClose>
                <SideMoreHeader>
                    <SideMoreHeaderTitle>Ver Mais</SideMoreHeaderTitle>
                    <SideMoreHeaderText>Escolha um tema.</SideMoreHeaderText>
                </SideMoreHeader>
                {
                    options?.map((item, key) => 
                        <SideMoreItem key={key} focusKey={`sidemore-${key+1}`} onPress={() => action(item)} onEnterPress={() => action(item)} >
                            <SideMoreItemText>{ item.title }</SideMoreItemText>
                        </SideMoreItem>
                    )
                }
            </SideMoreContainer> 
        </>
    )
})