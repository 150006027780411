import React from "react";

import {

    VideoContainer,
    VideoContent,
    VideoContentText,
    VideoProgressIndicator,
    VideoProgressIndicatorIn

} from './styled'
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { Load } from "../../ui/styled";

export default withFocusable({})(function VideoButton({item, spaced, focused, onPress, loading}){ 

    return (
        <>  
            <VideoContainer onPress={onPress} spaced={spaced}>
                <VideoContent source={item?.cover} focused={focused} loading={loading} >
                    { loading ? <Load flat /> : null }
                    {/* {
                        focused ? 
                            <VideoProgressIndicator>
                                <VideoProgressIndicatorIn />
                            </VideoProgressIndicator>
                        : null
                    } */}
                </VideoContent>
                <VideoContentText>{item?.title}</VideoContentText>
            </VideoContainer>
        </>
    )
})