import React, { useCallback, useRef } from "react"; 

import {  

    ContainerThemes,
    ThemesTitle,
    ContentThemes,
    ThemeItem,
    ThemeItemText, 
} from './styled'  
 
import { getScaledValue } from "../../../ui/styled";

export default function SearchThemes({ handleVertical, themes, searchBy }){ 
    
    const sliderRef = useRef(null);

    const CARD_WIDTH = getScaledValue(110);
    const modulesMargin = getScaledValue(0);

    const handleArrowPress = (direction, key) => {  
        console.log('SLID', direction, key)

        const focusSlider = (nextSlide) => {
            if (sliderRef.current) {
                const value = (CARD_WIDTH + modulesMargin) * nextSlide;
                sliderRef.current.style.left = `-${value}px`;
            }
        };
    
        switch (direction) {
            case "right": {
            const nextSlide = Number(key) + 1;
                if (nextSlide > themes.length - 1) return;
                return focusSlider(nextSlide);
            }
            case "left": {
            const nextSlide = Number(key) - 1;
                if (nextSlide < 0) return;
                return focusSlider(nextSlide);
            }
            default:
                if(handleVertical && typeof handleVertical === 'function' ){
                    handleVertical(direction, key)
                }
                return;
        };
        
    }

    return (
        <> 
            <ContainerThemes>
                <ThemesTitle>Buscar por temas</ThemesTitle>
                <ContentThemes ref={sliderRef}>
                    {
                        themes?.map((item, tkey) =>
                            <ThemeItem key={`theme-${tkey}`} onArrowPress={(a,b) => handleArrowPress(a, tkey)} onPress={() => searchBy('theme', item)} onEnterPress={() => searchBy('theme', item)}>
                                <ThemeItemText>{ item.title }</ThemeItemText>
                            </ThemeItem>
                        )
                    }
                </ContentThemes>
            </ContainerThemes>  
        </>
    )
}