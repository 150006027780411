
export const storageKey = 'Hoxx'

export const SaveObject = (key, value) => {
    return SaveStorage(key, JSON.stringify(value))
}

export const ReadObject = (key) => {
    return JSON.parse( ReadStorage(key))
} 
 

export const SaveStorage = (key, value) => {
    const cvalue = (value)
    try {
        return localStorage.setItem(`${ storageKey }::${ key }`, cvalue);
    } catch (e) {
        return sessionStorage.setItem(`${ storageKey }::${ key }`, cvalue);
    }
} 

export const ReadStorage = (key) => {
    let cvalue = ``
    try {
        cvalue = localStorage.getItem(`${ storageKey }::${ key }`); 
    } catch (e) {
        cvalue = sessionStorage.getItem(`${ storageKey }::${ key }`);
    }  
    if( cvalue !== '' && cvalue !== null ){
        return (cvalue);
    }
    return false
}