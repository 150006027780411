import { Dimensions } from 'react-native'
import styled from 'styled-components/native'    
// import { getScaledValue } from 'renative'; 
import Icons from './../assets/icons' 

const w = Dimensions.get('window').width
const h = Dimensions.get('window').height
export const getScaledValue = value => {
    return ( value * (h/500) )
}

export const hexToRgb = (hex) => {
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
        c= hex.substring(1).split('');
        if(c.length === 3){
            c= [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c= '0x'+c.join('');
        return [(c>>16)&255, (c>>8)&255, c&255].join(',') ;
    }
    return `255,255,255`
} 

export const Logo = styled(Icons).attrs({
    icon: 'logo',
    width: getScaledValue(90),
    height: getScaledValue(90) 
})`    
`;     


export const Load = styled.ActivityIndicator.attrs(p => ({
    color: 'white',
    marginTop: p.flat ? 0 : getScaledValue(40) 
}))`    
`;     
