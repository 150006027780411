import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';

import { 
    withFocusable
} from "@noriginmedia/react-spatial-navigation";


import { Button } from 'renative';
// import {
//     getScaledValue
// } from 'renative'; 
export const Container = styled.ImageBackground.attrs({
    source: require('./../../assets/background.png'),
    imageStyle:{
        resizeMode: "cover",
        alignSelf: "flex-end"
    }
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  

    background-color: #4BB6FB; 

    justify-content: center;
    align-items: center;
`;     

export const LogoImage = styled.ImageBackground.attrs({
    source: require('./../../assets/logo.png'),
    imageStyle:{
        resizeMode: "contain"
    },
})`     
    margin: 0 auto ${ getScaledValue(10) }px;
    width: ${ getScaledValue(160) }px
    height: ${ getScaledValue(80) }px;
`;     

export const ContentLogo = styled.View.attrs({
})`    
    flex-direction: row;
    align-items: center; 
    marginBottom: ${ getScaledValue(30) }px;
`;     


export const LogoIcon = styled(Icons).attrs({
    icon: 'logo',
    width: getScaledValue(103),
    height: getScaledValue(100) 
})`      
    margin-right: -${ getScaledValue(12) }px;
`;     

export const LogoText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(30) }px;
    font-weight: 300;
    color: #ffffff;
    margin-top: -${ getScaledValue(12) }px;
`;     




export const ContentWelcome = styled.View.attrs({
})`      
    transform: scale(.7); 
    marginBottom: ${ getScaledValue(20) }px;
`;     

export const WelcomeTitle = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(24) }px;
    text-align: center; 
    color: #ffffff;
    font-family: PTSans-Bold;
    margin-bottom: ${ getScaledValue(12) }px;
`;     

export const WelcomeText = styled.Text.attrs({
})`    
    text-align: center;
    font-size:  ${ getScaledValue(12) }px;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(12) }px;
    font-family: PTSans-Regular;
    ${
        props => props.bold ? `
            font-family: PTSans-Bold;
        ` : `
        `
    }
    ${
        props => props.bold ? `
            font-weight: bold;
        ` : `
        `
    }

    max-width: 40%;
    margin-left: auto;
    margin-right: auto;
`;     

export const ContentAccess = styled.Text.attrs({
})`     
    max-height: ${ getScaledValue(60) }px;
`;     


import PinInputEntry from "../../components/Pin";

export const PinInput = styled(PinInputEntry).attrs(props => ({
    placeholder:' ',
    digits: 5, 
    fields: props.fields
}))`    
    background-color: rgba(255,255,255,.2);
    borderRadius: ${ getScaledValue(3) }px;
    margin:  ${ getScaledValue(5) }px;
    width:  ${ getScaledValue(44) }px;
    height:  ${ getScaledValue(56) }px;
    border-width: .5px;
    font-family: Inter-Light;
    font-size:  ${ getScaledValue(16) }px;
    text-align: center;
    border-color:  rgba(255,255,255,.3);
    color:  #ffffff;
    cursor: auto;
`; 
   
 

export const AccessButtonText = styled.Text.attrs({
})`
    fontFamily: Inter-Light;
    color: #ffffff;
    fontSize: ${getScaledValue(10)}px;
`;    

export const AccessButton = withFocusable({   
})(styled.TouchableOpacity.attrs({
    textStyle:{
        fontFamily: 'Inter-Light',
        color: '#ffffff',
        fontSize: getScaledValue(14)
    }
})`     
    align-items: center;
    justify-content: center;
    marginHorizontal: ${ getScaledValue(20) }px;
    borderRadius: ${ getScaledValue(6) }px;
    height: ${ getScaledValue(27) }px;
    min-width: ${ getScaledValue(100) }px; 
    marginTop: ${ getScaledValue(0) }px;
    cursor:pointer;
    ${
        props => props.active ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : `
        ${
            props.focused ? `
                    background: #642EE5;
                    background: #f95e14;
                ` : ` 
                    background: #999999;
                `
            }
        `
    }
    
    

`); 
   
 


export const ContainerSideASide = styled.View.attrs({
})`      
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
`; 
   
export const ContentSideLogo = styled.View.attrs({
})`      
    flex:1; 
    align-items: center;
    justify-content: center;
`; 
   
export const ContentSide = styled.View.attrs({
})`       
    background: #000;
    width: 100%;
    padding: ${ getScaledValue(50) }px ${ getScaledValue(30) }px;
    
    justify-content: center;
    align-items: center;
    
    min-width: ${ getScaledValue(330) }px;
`; 
   

export const ContentMiddle = styled.View.attrs({
})`        
    flex: 1;
    align-items: center;
    justify-content: center;
    padding-bottom: ${ getScaledValue(50) }px;

    transform: scale(.7); 
`; 

export const ContentKeyboard = styled.View.attrs({
})`        
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: ${ getScaledValue(230) }px;
    transform: scale(.7); 
`; 

export const KeyboardKeyText = styled.Text.attrs({
})`
    fontFamily: Inter-Light;
    color: #ffffff;
    fontSize: ${getScaledValue(18)}px;
`;    

export const KeyboardKey = withFocusable({   
})(styled.TouchableOpacity.attrs({ 
})`        
    align-items: center;
    justify-content: center;
    min-width: ${ getScaledValue(40) }px;
    height: ${ getScaledValue(40) }px;
    background: rgba(255,255,255,.1);
    margin: ${ getScaledValue(8) }px 0 0;
    padding: 0 ${ getScaledValue(14) }px ;
    cursor: pointer;
    
    
    ${
        props => props.focused ? `
            background: #642EE5;
        ` : ` 
        `
    }
`);  
       