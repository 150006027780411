import React, { useState } from "react"; 

import { 

    BodyMenuItem,
    BodyMenuItemAction,
    BodyMenuIconContent,
    BodyMenuIcon,
    BodyMenuText, 
    BodyMenuImageContent

} from './styled' 

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export default withFocusable({})(function ButtonMenu({ item, active, onPress, focused }){  
    
    return (
        <>  
            <BodyMenuItemAction onPress={onPress} >
                <BodyMenuItem active={focused || active} > 
                    {
                        item.image ? <BodyMenuImageContent source={item.image} active={focused || active}  /> :
                        <BodyMenuIconContent active={focused || active}>
                            <BodyMenuIcon { ...item.icon } active={focused || active} />
                        </BodyMenuIconContent>
                    }
                    <BodyMenuText>{ item.title }</BodyMenuText> 
                </BodyMenuItem> 
            </BodyMenuItemAction>
        </>
    )
})