import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 
import { getScaledValue } from '../../ui/styled';
 
export const VideoContainer = styled.TouchableOpacity.attrs({
})`      
    ${
        props => props.spaced ? `
            border-radius: ${getScaledValue(3)}px; 
            margin-right: ${ getScaledValue(12) }px; 
            margin-bottom: ${ getScaledValue(12) }px; 
            overflow: hidden;
        ` : ``
    }
`;  
export const VideoContent = styled.ImageBackground.attrs({
    className:"lazy"
})`     
    height: ${ getScaledValue(77) }px; 
    width: ${ getScaledValue(127) }px; 
    background-color: rgba(255,255,255,.3);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    ${
        props => props.loading ? `
            justify-content: center;
        ` : ``
    }
    transition: all .3s ease;
    ${
        props => props.focused ? `
            border: ${getScaledValue(2)}px solid #f95e14;  
            opacity: .5;
            // transform:scale(1.1);
        ` : ``
    }
`;  
export const VideoProgressIndicator = styled.View.attrs({
})`     
    height: ${getScaledValue(2)}px;
    border-radius: ${getScaledValue(2)}px;
    background: #A7A7A7;
    margin: ${getScaledValue(4)}px
`;  

export const VideoProgressIndicatorIn = styled.View.attrs({
})`     
    height: ${getScaledValue(2)}px;
    border-radius: ${getScaledValue(2)}px;
    width: 50%;
    background: ${ props => props.theme.primary || '#f95e14' };
`;  

export const VideoContentText = styled.Text.attrs({
    numberOfLines:1
})`     
    width: ${ getScaledValue(110) }px; 
    margin: ${ getScaledValue(4) }px 0 0 ${ getScaledValue(4) }px;
    font-size:  ${ getScaledValue(7) }px; 
    color: #ffffff;
    font-family: PTSans-Regular;
`;  