
export const DEVELOPMENT_MODE = true
 
// export const API_ENDPOINT = 'https://hoxxtv-api.staging-xapps.dev/api'
export const API_ENDPOINT = 'https://api.hoxxtv.com.br/api'
// export const API_ENDPOINT = 'http://0.0.0.0:1337/api'
// export const API_ENDPOINT = 'https://4e99-189-6-27-229.ngrok-free.app/api'
export const AKAMAI_ENDPOINT = 'https://hoxxtv.b-cdn.net/'
export const SOCKET_ENDPOINT = 'https://api.hoxxtv.com.br'

export const GetHeaders = async (token) => {
	const headers = { 'Content-Type': 'application/json' } 
	if (token) {
		headers.Authorization = `Bearer ${token}`
	}
	return { headers }
}

export const ServerFetch = async (url, options, authenticated) => {
	const { headers } = await GetHeaders(authenticated)
	try{
		const response = await fetch(url, { ...options, headers }) 
		try{
			return await response.json()
		}catch(err){
			console.log('ServerParseError', err, url, { ...options, headers })
			return response
		} 
	}catch(error){
		console.log('ServerFetchError', error)
		return false
	}
}

export const GET = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'GET'
		},
		authenticated
	)
}

export const POST = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'POST',
			body: JSON.stringify(body)
		},
		authenticated
	)
}

export const PUT = async (path, body, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'PUT',
			body: JSON.stringify(body)
		},
		authenticated
	)
}

export const DELETE = async (path, authenticated = false) => {
	return await ServerFetch(
		`${API_ENDPOINT}${path}`,
		{
			method: 'DELETE'
		},
		authenticated
	)
}




export const ReadAddressesByZipCode = async (zipCode) => {
    try{
        let result = await fetch(`https://viacep.com.br/ws/${ zipCode }/json/`);
        return result.json();
    }catch(err){ return false; }
}
  

export const ResolveUri = (result) => {
    const path = result?.path ? result?.path 
                : result?.fileCopyUri ? result?.fileCopyUri 
                : result?.uri ? result?.uri 
                : result?.croppedImage ? result?.croppedImage 
                : typeof result === 'string' ? result : null     
    return path?.indexOf('file://') === -1 ? `file://${ path }` : path
}

export const PrepareFile = result => {
    const uri = ResolveUri(result)
    const name = uri.split('/').pop()
    const match = /\.(\w+)$/.exec(name)
    const type = match ? `image/${match[1]}` : `image`
    return { uri, name, type }
}

export const ServerUploadImage = async (result) => {  
      return await PostImage(PrepareFile(result))
}

export const PostImage = async (fileToUpload) => {
    const formData = new FormData()
    formData.append('files', fileToUpload, fileToUpload.name)  
    let { headers } = await GetHeaders(true) 
    delete headers['Content-Type']
    let response = await fetch(`${API_ENDPOINT}/upload`, { method: 'POST', body: formData, headers });   
    let responseJson = await response.json()  
    return responseJson?.[0]; 
}