import styled from 'styled-components/native'     
// import { getScaledValue } from 'renative';  

import { getScaledValue } from '../../../ui/styled';

export const UserProfileContent = styled.TouchableOpacity.attrs({
})`     
`;     

export const UserProfile = styled.ImageBackground.attrs({ 
})`    
    width: ${ getScaledValue(42) }px;
    height: ${ getScaledValue(42) }px; 
    background: ${ props => props.theme.primary || 'rgba(255,255,255,.1)' };
    border-radius: ${ getScaledValue(21) }px; 
    border-width: ${ getScaledValue(3) }px; 
    ${
        props => props.focused ? `
            border-color: ${ props.theme.primary || 'rgba(255,255,255,.1)' };
        ` : `
            border-color: rgba(255,255,255,.1);
        `
    }
    overflow: hidden;
`;      