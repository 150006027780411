import React, { useState } from "react";
import { useNavigate } from 'renative';

import {
    Container,
    ContentLogo,
    LogoIcon,
    LogoText,

    ContentWelcome,
    WelcomeTitle,
    WelcomeText,

    ContentAccess,
    PinInput,
    AccessButton,
    LogoImage
} from './styled'

import { View } from 'react-native';
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
import { hasWebFocusableUI } from '../../../config';

const FocusableView = hasWebFocusableUI ? withFocusable()(View) : View;

export default function AccessCode(props){

    const navigate = useNavigate(props);

    const [accessCode, setAccessCode] = useState([]) 

    const next = () => {
        console.log('Access with code :: ', accessCode.join('') )
        navigate('select-profile')
    } 

    return props.path !== "/access-code" ? null : (
        <>
            <Container>
                 

                <LogoImage />
                <ContentWelcome>
                    <WelcomeTitle>Olá, bem-vindo(a)!</WelcomeTitle>
                    <WelcomeText>Para entrar no HoxxTV, acesse o menu <WelcomeText bold>Perfil</WelcomeText> no aplicativo, depois aperte em <WelcomeText bold>Acesso a TV</WelcomeText> e digite o código gerado nos campos abaixo:</WelcomeText>
                </ContentWelcome>

                <FocusableView>
                    <ContentAccess>
                        <PinInput onInputChange={setAccessCode} />
                    </ContentAccess>
                    <AccessButton title={'Entrar'} active={accessCode?.length >= 5} onPress={next} />
                </FocusableView>

            </Container>
        </>
    )
}