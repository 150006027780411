import React from "react"; 

import { 
    ContentBody, 
    FavoriteDecorationPosition,
    FavoriteDecoration,
    FavoriteContainer,
    FavoriteContent,
    FavoriteIcon,
    FavoriteText
} from './styled' 
 

export default function FavoritesEmpty(){  

    return ( 
        <>  
            <ContentBody>
                <FavoriteContainer>
                    <FavoriteDecorationPosition>
                        <FavoriteDecoration />
                    </FavoriteDecorationPosition>
                    <FavoriteContent>
                        <FavoriteIcon />
                    </FavoriteContent> 
                    <FavoriteText>
                        Os vídeos marcados como Favoritos no aplicativo HoxxTV aparecem neste menu.
                    </FavoriteText>
                </FavoriteContainer>
            </ContentBody> 
        </>
    )
} 