import styled from 'styled-components/native'    
// import { getScaledValue } from 'renative'; 

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';
 
export const ContentKeyboard = styled.View.attrs({
})`      
    width: ${getScaledValue(291)}px;
    flex-direction: row;
    flex-wrap: wrap; 
    margin-right: ${getScaledValue(46)}px;
`;    
export const KeyboardKey = withFocusable()(styled.TouchableOpacity.attrs({ 
})`      
    position: relative;
    width: ${getScaledValue(27)}px;
    height: ${getScaledValue(27)}px;
    border-radius: ${getScaledValue(6)}px;
    background: rgba(255,255,255,.1);
    border-color: rgba(255,255,255,.2);
    border-width: 1;
    align-items: center;
    justify-content: center;
    margin: 0 ${ getScaledValue(4) }px ${ getScaledValue(4) }px 0; 

    ${
        props => props.action ? `
            width: ${getScaledValue(62)}px;
            margin: 0 ${ getScaledValue(4) }px ${ getScaledValue(4) }px ${ getScaledValue(8) }px; 
        ` : ``
    }

    ${
        props => props.space ? `
            width: ${getScaledValue(105)}px; 
            margin: ${ getScaledValue(14) }px ${ getScaledValue(7) }px ${ getScaledValue(0) }px 0; 
        ` : ``
    }

    ${
        props => props.search ? `
            width: ${getScaledValue(175)}px; 
            margin: ${ getScaledValue(14) }px ${ getScaledValue(4) }px ${ getScaledValue(0) }px 0; 
        ` : ``
    } 

    ${
        props => props.focused ? `
            background: #f95e14;
        ` : ``
    } 
`);    

export const EmptyKey = styled.View.attrs({ 
})`       
    width: ${getScaledValue(27)}px;
    height: ${getScaledValue(27)}px;
    border-radius: ${getScaledValue(6)}px;  
    margin: 0 ${ getScaledValue(4) }px ${ getScaledValue(4) }px 0;  
`;    
export const KeyboardKeyText = styled.Text.attrs({
})`   
    font-size: ${ getScaledValue(14) }px; 
    font-family: PTSans-Regular;
    color: #ffffff;
    ${
        props => props.small ? `
            font-size: ${ getScaledValue(9) }px; 
        ` : `
            ${
                !props.isUpper ? `
                    text-transform: lowercase;
                ` : ``
            }
        `
    }
`;   

export const KeyboardKeyIcon = styled(Icons).attrs({
})`    
`;  
 
export const KeyboardKeyDecoration = styled.View.attrs({
})`      
    position: absolute;
    top: ${getScaledValue(2)}px;
    right: ${getScaledValue(2)}px;

    width: ${getScaledValue(5)}px;
    height: ${getScaledValue(5)}px;
    
    border-radius: ${getScaledValue(2.5)}px;
    background: ${ props => props.theme.primary || '#f95e14'  };
`;     




 