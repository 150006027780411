import React, { useEffect, useState } from 'react';

export default function useRemoteControl(next){



    // switch (event.keyCode) {
    //     case 10009: // Return/Back button
    //       console.log('Return/Back button pressed');
    //       // Handle return/back button action
    //       break;
    //     case 415: // Play/Pause button (example keycode)
    //       console.log('Play/Pause button pressed');
    //       // Handle play/pause action
    //       break;
    //     case 412: // Previous Track button (example keycode)
    //       console.log('Previous Track button pressed');
    //       // Handle previous track action
    //       break;
    //     case 418: // Next Track button (example keycode)
    //       console.log('Next Track button pressed');
    //       // Handle next track action
    //       break;
    //     case 413: // Stop button (example keycode)
    //       console.log('Stop button pressed');
    //       // Handle stop action
    //       break;
    //     default:
    //       // Handle other keycodes or keys
    //       break;
    // }



    const [pressed, setPressed] = useState([])

  
    useEffect(() => {

        const handleKeyDown = (event) => {
            // Log keycode of the pressed key
            console.log('Key pressed: ' + event.keyCode);

            // setPressed([ ...pressed, `PRE TIZEN`])
            // const rvalue = window.tizen.tvinputdevice.getSupportedKeys();
            // setPressed([ ...pressed, `${rvalue?.join(',')}`])

            // setPressed([ ...pressed, `${event.keyCode}`])
    
            if(next && typeof next === 'function'){

                if(event.keyCode === 37){
                    // left button
                }
                
                if(event.keyCode === 39){
                    // right button 
                }
                
                if(event.keyCode === 38){
                    // up button 
                }
                
                if(event.keyCode === 40){
                    // down button 
                }

                //forward
                next(event.keyCode)

            } else {

                // Example: Alert the keycode
                // alert('Key pressed: ' + event.keyCode);
    
    
                if(event.keyCode === 10009){
                    // back button
                }
    
                if(event.keyCode === 10182 ){
                    // exit button
                }

            }

        };
        
        // Add event listener when component mounts
        document.addEventListener('keydown', handleKeyDown);
    
        // Clean up event listener when component unmounts
        return () => {
          document.removeEventListener('keydown', handleKeyDown);
        };
      }, []);


    return {
        pressed
    }

};
