import React, { useContext, useEffect } from "react";
import { CoreContext } from "../../../context/CoreContext";
import ModalWrapper from "../Wrapper";

import { 
    ModalHeaderContainer,
    ModalHeaderIcon,
    ModalHeaderClose,
    ModalHeaderCloseIcon,
    ModalHeaderSpace,


    ModalContainer,
    ModalBody,
    ModalTitle,
    ModalText,
    ModalAction,
    ModalActionText
} from './styled'

export default function ModalAlert({ title, text, action, icon, closeable, setFocus, opened }){

    const { setModal } = useContext(CoreContext)

    const close = () => {
        setModal(null)
    }

    const handleBlurred = () => { 
        setModal(null)
    }

    const handleAction = () => {
        if(action?.action && typeof action?.action === 'function'){
            action?.action()
        }
        close()
    }

    useEffect(() => {
        if(setFocus && typeof setFocus === 'function'){
            setFocus('action-button')
        }
    }, [])
    
    return (
        <>
            <ModalWrapper opened={opened} onBecameBlurred={handleBlurred}>
                    <ModalHeaderContainer>
                        <ModalHeaderSpace />
                        <ModalHeaderIcon { ...icon } />
                        {
                            closeable ? 
                                <ModalHeaderClose onEnterPress={close} onPress={close}>
                                    <ModalHeaderCloseIcon /> 
                                </ModalHeaderClose> 
                            : 
                            <ModalHeaderSpace />
                        }
                    </ModalHeaderContainer>
                    <ModalBody>

                        <ModalTitle>{ title }</ModalTitle>
                        <ModalText>{ text }</ModalText> 
                        {
                            action ? 
                                <ModalAction focusKey={'action-button'} onEnterPress={handleAction} onPress={handleAction}>
                                    <ModalActionText>{ action?.title }</ModalActionText>
                                </ModalAction>
                            : null
                        } 
                    </ModalBody> 
            </ModalWrapper>
        </>
    )
}