import React from "react";

import {
    Container 
} from './styled'

import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export default withFocusable({ 
    trackChildren: true,
})(function ModalWrapper({ children, opened }){
    
    return (
        <>
            <Container opened={opened}>
                { children }
            </Container>
        </>
    )
})