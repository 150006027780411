import styled from 'styled-components/native' 

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

import { withFocusable } from '@noriginmedia/react-spatial-navigation';
 
export const ContainerProfiles = styled.View.attrs({
})`      
`;    
export const ProfilesTitle = styled.Text.attrs({
})`      
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Bold;
    color: #ffffff;
    margin-top: ${ getScaledValue(38) }px; 
    margin-bottom: ${ getScaledValue(10) }px; 
`;    
export const ContentProfiles = styled.View.attrs({
    horizontal: true,
    showsHorizontalScrollIndicator:false
})`   
    flex-direction: row;
`;    
export const ProfileItem = withFocusable()(styled.TouchableOpacity.attrs({
})`     
    width: ${getScaledValue(52)}px;
    height: ${getScaledValue(52)}px; 
    border-radius: ${getScaledValue(26)}px; 
    margin-right: ${ getScaledValue(10) }px; 
    overflow: hidden;
    ${
        props => props.focused ? `
            border-width: ${getScaledValue(2)}px;
            border-color: ${ props.theme.primary || '#f95e14' };
        ` : ``
    }
`);    
export const ProfileItemImage = styled.ImageBackground.attrs({
})`      

    width: ${getScaledValue(52)}px;
    height: ${getScaledValue(52)}px; 
    border-radius: ${getScaledValue(26)}px; 
    background: rgba(255,255,255,.2);
    margin-right: ${ getScaledValue(9) }px; 
    margin-bottom: ${ getScaledValue(9) }px; 
`;     



 