import React, { useState, createContext, useEffect } from 'react'
import { AddFavorite, ReadFavorites, RemoveFavorite } from '../services/favorites'
import { availableUsers } from '../services/mock'
import { parseVideo } from '../utils/parser'
import { ReadObject, SaveObject } from '../services/storage'
 
export const CoreContext = createContext({})

export const CoreState = ({ children }) => { 
	 
	const [ favoriting, setFavoriting ] = useState(false)  
	const [ searchExpression, setSearchExpression] = useState([]) 
	const [ moreCategory,  setMoreCategory] = useState(null) 
	const [ next, setNext] = useState(null) 
	const [ modal, setModal] = useState(null) 
	
	// const [ authentication, setAuthentication] = useState(null) 
	
	const [authentication, setAuthentication] = useState(ReadObject('authentication') ? ReadObject('authentication') : null)
	// const [authentication, setAuthentication] = useState(null)

	const [ currentVideo, setCurrentVideo] = useState(ReadObject('currentVideo') ? ReadObject('currentVideo') : null) 
	const [ favorites, setFavorites] = useState(ReadObject('favorites') ? ReadObject('favorites') : []) 
	const [ currentProfile, setCurrentProfile] = useState(ReadObject('currentProfile') ? ReadObject('currentProfile') : null) 
	const [ profiles, setProfiles] = useState(ReadObject('profiles') ? ReadObject('profiles') : [])  
	const [ user, setUser] = useState(ReadObject('user') ? ReadObject('user') : null)

	const [ side, setSide] = useState(ReadObject('side') ? ReadObject('side') : null) 

    const [isOnline, setIsOnline] = useState(navigator.onLine);

	
	const isFavorite = item => {
		return favorites?.map(mit => mit?.id).includes(parseInt(item?.id))
	}

	const toggleFavorite = async item => { 
		setFavoriting(true)
		if(isFavorite(item)){
			const fav = favorites.find(mit => mit.id == item.id)
			await RemoveFavorite(currentProfile?.id, fav.favorite_id, authentication?.jwt)
		}else{
			await AddFavorite(currentProfile?.id, { data: { stream_show: item.id } },  authentication?.jwt)
		}
		await reloadFavorites()
		setFavoriting(false)
	}

	const reloadFavorites = async () => {
		const result = await ReadFavorites(currentProfile?.id, authentication?.jwt)
		if(result?.data?.length){ setFavorites(result?.data?.map(mit => ({ favorite_id: mit?.id, ...parseVideo(mit?.stream_show) }) ) ) ;}else{ setFavorites([]) ;}
	}

	useEffect(() => {
		reloadFavorites()
	},[])
	
	const contextValue = { 
		currentVideo, setCurrentVideo,
        favorites, setFavorites,
		searchExpression, setSearchExpression,
		currentProfile, setCurrentProfile,
		moreCategory,  setMoreCategory,
		modal, setModal,
		side, setSide,
		isFavorite, toggleFavorite, favoriting, reloadFavorites,
		authentication, setAuthentication,
		profiles, setProfiles,
		user, setUser,
		next, setNext,
		isOnline, setIsOnline
	} 

	// to persist state when app reload  
	useEffect(() => { SaveObject('authentication', authentication); }, [authentication])
	useEffect(() => { SaveObject('currentVideo', currentVideo); }, [currentVideo])
	useEffect(() => { SaveObject('favorites', favorites); }, [favorites])
	useEffect(() => { SaveObject('currentProfile', currentProfile); }, [currentProfile])
	useEffect(() => { SaveObject('profiles', profiles); }, [profiles])
	useEffect(() => { SaveObject('user', user); }, [user])
	useEffect(() => { SaveObject('side', side); }, [side])

	return <CoreContext.Provider value={contextValue}>{children}</CoreContext.Provider>
}
