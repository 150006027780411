import styled from 'styled-components/native'    

import Icons from './../../assets/icons' 
import { getScaledValue } from '../../ui/styled';

import { 
    withFocusable
} from "@noriginmedia/react-spatial-navigation";

// import {
//     getScaledValue
// } from 'renative'; 

export const Container = styled.View.attrs({
})`   
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  

    background-color: #000000; 

    justify-content: center;
    align-items: center;

    padding-bottom: ${ getScaledValue(100) }px;
`;     

export const ContentLogo = styled.View.attrs({
})`    
    position: absolute;
    top:${ getScaledValue(30) }px;
    left:${ getScaledValue(40) }px;
    flex-direction: row;
    align-items: center;  
`;     

export const LogoIcon = styled(Icons).attrs({
    icon: 'logo',
    width: getScaledValue(50),
    height: getScaledValue(50) 
})`  
    margin-right: -${ getScaledValue(6) }px;
`;     

export const LogoText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(15) }px;
    color: #ffffff;
    margin-top: -${ getScaledValue(6) }px;
`;     




export const ContentWelcome = styled.View.attrs({
})`     
    max-width: ${ getScaledValue(300) }px;
    margin-bottom: 20px;
`;     

export const WelcomeTitle = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(24) }px;
    text-align: center; 
    color: #ffffff;
    font-family: PTSans-Bold;
    margin-bottom: ${ getScaledValue(12) }px;
`;     
 




export const ContentProfiles = styled.View.attrs({
})`     
    max-width: ${ getScaledValue(300) }px;
    margin-bottom: 20px;
    background: rgba(255,255,255,.1);
    flex-direction:row;
    padding: ${ getScaledValue(14) }px 0;
    border-radius: ${ getScaledValue(6) }px;
`;     

export const ProfileItem = withFocusable({   
})(styled.TouchableOpacity.attrs({
})`      
    margin: 0 ${ getScaledValue(14) }px;
`);     

export const ProfileItemImage = styled.ImageBackground.attrs({
})`      
    width: ${ getScaledValue(70) }px;
    height: ${ getScaledValue(70) }px;
    border-radius: ${ getScaledValue(35) }px;
    overflow: hidden;
    ${
        props => props.focused ? `
            border-width: ${ getScaledValue(2) }px;
            border-color: #f95e14;
        ` : ``
    }
`;     

export const ProfileItemText = styled.Text.attrs({
})`    
    font-size:  ${ getScaledValue(12) }px;
    text-align: center; 
    font-family: PTSans-Bold;
    margin-top: ${ getScaledValue(11) }px;
    ${
        props => props.focused ? `
            color: #f95e14;
        ` : `
            color: #A7A7A7;
        `
    }
`;     
 

export const LogoImage = styled.ImageBackground.attrs({
    source: require('./../../assets/logo.png'),
    imageStyle:{
        resizeMode: "contain"
    },
})`     
    margin: 0 auto ${ getScaledValue(10) }px;
    width: ${ getScaledValue(160) }px
    height: ${ getScaledValue(60) }px;
`;     