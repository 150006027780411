// import { getScaledValue } from 'renative';
import { getScaledValue } from '../../ui/styled';

import UserImage_1 from './../../assets/users/user-1.png'
import UserImage_2 from './../../assets/users/user-2.png'
import UserImage_3 from './../../assets/users/user-3.png'

export const availableCatalogs = [
    { id:1, title:'search', icon:{ icon:'search', width:getScaledValue(9), height:getScaledValue(11), stroke:'#ffffff' }, location:'search' },
    { id:2, title:'home', icon:{ icon:'home', width:getScaledValue(15), height:getScaledValue(17), fill:'#ffffff' }, location:'catalog' },
    { id:3, title:'favorites', icon:{ icon:'heart', width:getScaledValue(14), height:getScaledValue(12), fill:'#ffffff' }, location:'favorites' },
]

export const availableContents = [
    { id:1, title:'Playlist', icon:{ icon:'play', width:getScaledValue(9), height:getScaledValue(11), fill:'#ffffff' } }, 
    { id:5, title:'Ver Mais', icon:{ icon:'plus', width:getScaledValue(14), height:getScaledValue(12), fill:'#ffffff' } },
]

export const availableCatagories = [
    { id:1, title:'Meus Favoritos', icon:{ icon:'liked', width:getScaledValue(22), height:getScaledValue(20), fill:'#f95e14' } },
    { id:2, title:'As 20 Músicas + Vistas', icon:{ icon:'music', width:getScaledValue(17), height:getScaledValue(21), fill:'#f95e14' } },
    { id:3, title:'Cante e Dance', icon:{ icon:'music', width:getScaledValue(17), height:getScaledValue(21), fill:'#f95e14' } },
]

export const availableVideos = [
    { id:1, title:'Jesus nosso salvador', cover: require('./../../assets/videos/video-1.png'), category_id:1 },
    { id:2, title:'Louvem com Jesus', cover: require('./../../assets/videos/video-2.png'), category_id:1 },
    { id:3, title:'O Menino Jesus - Hoje é seu aniversário', cover: require('./../../assets/videos/video-3.png'), category_id:1 },
    { id:4, title:'Noite Feliz', cover: require('./../../assets/videos/video-4.png'), category_id:1 },
    { id:5, title:'Samuel', cover: require('./../../assets/videos/video-5.png'), category_id:1 },
    { id:6, title:'Minha Pequena Luz', cover: require('./../../assets/videos/video-6.png'), category_id:1 },
    { id:7, title:'Eu preciso de você', cover: require('./../../assets/videos/video-7.png'), category_id:1 },
    { id:8, title:'Por que é importante ler a bíblia?', cover: require('./../../assets/videos/video-8.png'), category_id:1 },
    { id:9, title:'Desenhos bíblicos', cover: require('./../../assets/videos/video-9.png'), category_id:1 },
    { id:10, title:'Partiu ler a Bíblia', cover: require('./../../assets/videos/video-10.png'), category_id:1 },
    { id:11, title:'Bíblia para crianças', cover: require('./../../assets/videos/video-11.png'), category_id:1 },
    { id:12, title:'Jesus nosso salvador', cover: require('./../../assets/videos/video-12.png'), category_id:1 },


    { id:20, title:'Por que é importante ler a bíblia?', cover: require('./../../assets/videos/video-8.png'), category_id:2 }, 
    { id:19, title:'Eu preciso de você', cover: require('./../../assets/videos/video-7.png'), category_id:2 },
    { id:18, title:'Minha Pequena Luz', cover: require('./../../assets/videos/video-6.png'), category_id:2 },
    { id:17, title:'Samuel', cover: require('./../../assets/videos/video-5.png'), category_id:2 },
    { id:16, title:'Noite Feliz', cover: require('./../../assets/videos/video-4.png'), category_id:2 },
    { id:15, title:'O Menino Jesus - Hoje é seu aniversário', cover: require('./../../assets/videos/video-3.png'), category_id:2 },
    { id:14, title:'Louvem com Jesus', cover: require('./../../assets/videos/video-2.png'), category_id:2 },
    { id:13, title:'Jesus nosso salvador', cover: require('./../../assets/videos/video-1.png'), category_id:2 },

    { id:27, title:'Eu preciso de você', cover: require('./../../assets/videos/video-7.png'), category_id:3 },
    { id:25, title:'Samuel', cover: require('./../../assets/videos/video-5.png'), category_id:3 },
    { id:23, title:'O Menino Jesus - Hoje é seu aniversário', cover: require('./../../assets/videos/video-3.png'), category_id:3 },
    { id:21, title:'Jesus nosso salvador', cover: require('./../../assets/videos/video-1.png'), category_id:3 },
    { id:22, title:'Louvem com Jesus', cover: require('./../../assets/videos/video-2.png'), category_id:3 },
    { id:24, title:'Noite Feliz', cover: require('./../../assets/videos/video-4.png'), category_id:3 },
    { id:26, title:'Minha Pequena Luz', cover: require('./../../assets/videos/video-6.png'), category_id:3 },
]


export const availableKeysSide1 = [
    { key:'A' },
    { key:'B' },
    { key:'C' },
    { key:'D' },
    { key:'E' },
    { key:'F' },
    { key:'G' },
    { icon:{ icon:'backspace', width: getScaledValue(14), height: getScaledValue(10), fill: '#ffffff' } },

    { key:'H' },
    { key:'I' },
    { key:'J' },
    { key:'K' },
    { key:'L' },
    { key:'M' },
    { key:'N' },
    { action:{ title:'Limpar' } },

    { key:'O' },
    { key:'P' },
    { key:'Q' },
    { key:'R' },
    { key:'S' },
    { key:'T' },
    { key:'U' },
    { action:{ title:'&123', mode:2 } },

    { key:'V' },
    { key:'W' },
    { key:'X' },
    { key:'Y' },
    { key:'Z' },
    { key:'-' },
    { key:'?' },
    { icon:{ icon:'shift', width: getScaledValue(12), height: getScaledValue(14), fill: '#ffffff' } },

    { space:true, icon:{ icon:'space', width: getScaledValue(24), height: getScaledValue(6), stroke: '#ffffff' } },
    { search:true }
    
]


export const availableKeysSide2 = [
    { key:'1' },
    { key:'2' },
    { key:'3' },
    { key:'4' },
    { key:'5' },
    { key:'6' },
    { key:'7' },
    { icon:{ icon:'backspace', width: getScaledValue(14), height: getScaledValue(10), fill: '#ffffff' } },

    { key:'8' },
    { key:'9' },
    { key:'0' },
    { key:'!' },
    { key:'@' },
    { key:'#' },
    { key:'$' },
    { action:{ title:'Limpar' } },

    { key:'%' },
    { key:'&' },
    { key:'*' },
    { key:'(' },
    { key:')' },
    { key:'_' },
    { key:'-' },
    { action:{ title:'ABC', mode:1 } },

    { key:':' },
    { key:';' },
    { key:',' },
    { key:'.' },
    { key:'!' },
    { key:'?' },
    { empty:true },
    { icon:{ icon:'shift', width: getScaledValue(12), height: getScaledValue(14), fill: '#ffffff' } },

    { space:true, icon:{ icon:'space', width: getScaledValue(24), height: getScaledValue(6), stroke: '#ffffff' } },
    { search:true }
    
]

export const availableThemes = [
    { title:'As 20 Músicas + Vistas' },
    { title:'Cante e Dance' },
    { title:'Cante e Dance' },
    { title:'Minha vida é uma viagem' },
    { title:'Turma do Cristãozinho' },
    { title:'3 Palavrinhas' },
]

export const availableCharacters = [
    { image: require('./../../assets/users/user-1.png') },
    { image: require('./../../assets/users/user-3.png') },
    { image: require('./../../assets/users/user-2.png') },
    { image: require('./../../assets/users/user-3.png') },
    { image: require('./../../assets/users/user-3.png') },
    { image: require('./../../assets/users/user-1.png') },
    { image: require('./../../assets/users/user-3.png') },
    { image: require('./../../assets/users/user-2.png') },
    { image: require('./../../assets/users/user-3.png') },
    { image: require('./../../assets/users/user-3.png') }
]

export const availableUsers = [
    { name:'Marcelo', image: UserImage_1, theme:'blue' },
    { name:'Clarisse', image: UserImage_2, theme:'pink' },
    { name:'Lucas', image: UserImage_3, theme:'blue' },
]