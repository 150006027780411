import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons'
import { getScaledValue } from '../../../ui/styled'; 

 

export const VideoInfos = styled.View.attrs({
})`    
    flex-direction: row;
    align-items: center;
`; 

export const VideoImage = styled.ImageBackground.attrs({ 
})`    
    width: ${getScaledValue(46)}px;
    height: ${getScaledValue(46)}px;
    border-radius: ${getScaledValue(23)}px;
    border-width: 3px;
    border-color: #ffffff;
    overflow: hidden;
`;     

export const VideoInfosContent = styled.View.attrs({
})`    
    padding-left: ${ getScaledValue(8) }px;
`;     

export const VideosInfosText = styled.Text.attrs({
})`    
    font-family: PTSans-Bold;
    font-size: 12px;
    color: #ffffff;
`;     

export const VideosInfosTitle = styled.Text.attrs({
})`     
    font-family: PTSans-Bold;
    font-size: 21px;
    color: #ffffff;
`;     



 