import styled from 'styled-components/native'    

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

// import {
//     getScaledValue
// } from 'renative'; 

export const ContainerScroll = styled.ScrollView.attrs({
    showsVerticalScrollIndicator:false
})`          
`;     

export const ContainerVideo = styled.ImageBackground.attrs({
    source: require('./../../../assets/videos/video-1.png')
})`   
 
`;     

export const VideoContainer = styled.View.attrs({
})`    
    padding: ${getScaledValue(24)}px ${getScaledValue(24)}px 0;
    background: rgba(0,0,0,.3);
`;     

export const VideoHeaderContainer = styled.View.attrs({
})`    
    flex-direction: row;
`;     

export const VideoClose = withFocusable()(styled.TouchableOpacity.attrs({
})`    
    width: ${getScaledValue(46)}px;
    height: ${getScaledValue(46)}px;
    border-radius: ${getScaledValue(23)}px;
 
    background: ${ props => !props.focused ? `rgba(255,255,255,.14)` :  props.theme.primary || '#f95e14'  };
    align-items: center;
    justify-content: center;
    margin-right: ${ getScaledValue(13) }px;
`);     

export const VideoCloseIcon = styled(Icons).attrs({
    icon: 'close',
    width: getScaledValue(17),
    height: getScaledValue(17),
    stroke: '#ffffff'
})`    
`;     

export const VideoInfos = styled.View.attrs({
})`    
    flex-direction: row;
    align-items: center;
`; 

export const VideoImage = styled.ImageBackground.attrs({
    source: require('./../../../assets/users/user-1.png')
})`    
    width: ${getScaledValue(46)}px;
    height: ${getScaledValue(46)}px;
    border-radius: ${getScaledValue(23)}px;
    border-width: 3px;
    border-color: #ffffff;
`;     

export const VideoInfosContent = styled.View.attrs({
})`    
    padding-left: ${ getScaledValue(8) }px;
`;     

export const VideosInfosText = styled.Text.attrs({
})`    
    font-family: PTSans-Bold;
    font-size: 12px;
    color: #ffffff;
`;     

export const VideosInfosTitle = styled.Text.attrs({
})`     
    font-family: PTSans-Bold;
    font-size: 21px;
    color: #ffffff;
`;     





export const ContentInfos = styled.View.attrs({
})`    
    flex-direction: row;
    padding: 0 ${getScaledValue(50)}px; 
    min-height: 50%;  
`;     
export const InfoSide = styled.View.attrs({
})`    
    flex:1; 
`;     
export const InfoSideTouch = styled.TouchableOpacity.attrs({
})`     
    flex:1; 
    align-items: center;
    justify-content: center; 
`;     
export const InfoTitle = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(28)}px;
    color: #ffffff;
    margin-top: ${ getScaledValue(10) }px;

`;     
export const InfoDescription = styled.Text.attrs({
})`    
    font-family: PTSans-Bold;
    font-size: ${ getScaledValue(12)}px;
    color: #ffffff;
    margin-top: ${ getScaledValue(10) }px;
    max-width: 80%;
`;     
export const ButtonPlay = styled.TouchableOpacity.attrs({
})`    
    width: ${getScaledValue(80)}px;
    height: ${getScaledValue(80)}px;
    border-radius:  ${getScaledValue(40)};
    background: ${ props => props.theme.primary || '#f95e14' };
    align-items: center;
    justify-content: center;
`;     
export const ButtonPlayIcon = styled(Icons).attrs({
    icon: 'play',
    width: getScaledValue(26),
    height: getScaledValue(26),
    fill: "#ffffff"
})`    

`;      




    
export const ProgressText = styled.Text.attrs({
})`
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12)}px;
    color: #ffffff;
`; 
export const ContentProgress = styled.View.attrs({
})`    
    flex-direction: row;
    align-items: center;
    margin: ${getScaledValue(10)}px 0;
`;     
export const ProgressBar = styled.View.attrs({
})`
    position: relative;
    height: ${ getScaledValue(3) }px;
    flex:1;
    background: #ffffff;
    margin: 0 ${ getScaledValue(12) }px;
`;     
export const ProgressBarDecoration = styled.View.attrs({
})`
    height: ${ getScaledValue(3) }px; 
    background: ${ props => props.theme.primary || '#f95e14' };
    width: 10%; 
`;     
export const ProgressBarIndicator = styled.TouchableOpacity.attrs({
})`
    width: ${ getScaledValue(25) }px;
    height: ${ getScaledValue(25) }px;
    border-radius: ${ getScaledValue(12.5) }px;
    background: ${ props => props.theme.primary || '#f95e14' };
    margin-top: -${ getScaledValue(14) }px;
    margin-left: 10%;
`;     





 

export const RecommendationContainer = styled.View.attrs({
})`     
    background: rgba(0,0,0,.9);
    margin: 0 -${getScaledValue(24)}px -${getScaledValue(24)}px;
    padding: ${getScaledValue(24)}px ${getScaledValue(24)}px;
`;     
export const RecommendationTitle = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(14)}px;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(8) }px;
`;     
export const RecommendationContent = styled.ScrollView.attrs({
    horizontal: true,
    showsHorizontalScrollIndicator:false
})`    
`;     
export const RecommendationVideo = styled.TouchableOpacity.attrs({
})`      
`;     
export const RecommendationVideoImage = styled.ImageBackground.attrs({
})`     
    width: ${ getScaledValue(93) }px;
    height: ${ getScaledValue(131) }px;
    background: #ffffff;
    margin-right: ${ getScaledValue(12) }px;
`;     


 

export const ContentSideMenu = styled.View.attrs({
})`         
    position: absolute;
    top: 0; 
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.2);
    align-items: flex-end; 
    max-width:  ${ getScaledValue(320) }px;

`;     

export const SideItem = styled.View.attrs({
})`      
    width: ${ getScaledValue(170) }px;
    height: ${ getScaledValue(70) }px;
    background: #35245D;
    margin-bottom: ${ getScaledValue(12) }px;
    flex-direction: row;
    align-items: center;
`;     

export const SideItemImage = styled.ImageBackground.attrs({
    resizeMode: 'cover',
    source: require('./../../../assets/videos/video-13.png')
})`      
    width: ${ getScaledValue(50) }px;
    height: ${ getScaledValue(70) }px;
    background: #fff;
`;     

export const SideText = styled.Text.attrs({
})`      
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12)}px;
    padding: 0 ${ getScaledValue(12)}px;
    color: #ffffff;
`;     




export const ContentSideSide = styled.ScrollView.attrs({
})`      
    padding: ${ getScaledValue(24)}px ${ getScaledValue(25)}px;
    background: #f95e14;
`;     

export const ContentSideTitle = styled.Text.attrs({
})`      
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(24)}px;
    padding: 0 ${ getScaledValue(12)}px;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(21)}px;
`;     
