import React, { useEffect, useState } from "react"; 

import { 
    ContentSide,
    LogoIcon, 
    LogoOut,

    SideOptions
} from './styled' 

import SideButton from './SideButton' 
import SideUserButton from "./SideUserButton";

export default function Side({ availableCatalogs, currentCatalog, selectCatalog, selectProfile }){ 
    return (
        <>
            <ContentSide> 
                <LogoOut>
                    <LogoIcon />
                </LogoOut>
                <SideOptions>
                    {
                        availableCatalogs.map((item, key) => 
                            <SideButton 
                                key={key} 
                                focusKey={`sideoption-${key+1}`} 
                                item={item} 
                                onPress={() => selectCatalog(item)} 
                                onEnterPress={() => selectCatalog(item)} 
                                active={ currentCatalog?.id === item?.id } 
                                first={key === 0} last={ (key === (availableCatalogs.length - 1)) } 
                            />
                        )
                    }  
                </SideOptions> 
                <SideUserButton 
                    onPress={() => selectProfile()} 
                    onEnterPress={() => selectProfile()} 
                    focusKey={`sideoption-4`}  
                />
            </ContentSide>   
        </>
    )
}