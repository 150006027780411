import React, { useEffect, useState } from "react"; 

import {  
    ContentKeyboard,
    KeyboardKey,
    EmptyKey,
    KeyboardKeyDecoration,
    KeyboardKeyText, 
    KeyboardKeyIcon

} from './styled'  

import { availableKeysSide1, availableKeysSide2 } from "../../services/mock"; 

export default function Keyboard({ value, onChangeText, onSearch, clear }){ 

    const [isUpper, setIsUpper] = useState(false)
    const [mode, setMode] = useState(1)
    const [typedText, setTypedText] = useState( value ? value : '')

    const action = item => {
        if(item.key){ setTypedText(`${ typedText }${ isUpper ? item.key : item.key.toLowerCase() }`) ;}
        if(item.action){ handleAction(item) ;}
        if(item.icon){ handleIcon(item) ;}
        if(item.space){ setTypedText(`${ typedText } `) ;}
        if(item.search){ safeHandleSearch() ;}
    }
    const backspace = () => {
        setTypedText(`${ typedText }`.slice(0, -1))
    }

    const handleAction = item => {
        if(item.action.mode){ setMode(item.action.mode) ;}
        if(item.action.title === 'Limpar'){ setTypedText(''); clear() ;}
    }

    const handleIcon = item => {
        if(item.icon.icon === 'shift'){ setIsUpper(!isUpper) ;}
        if(item.icon.icon === 'backspace'){ backspace() ;}
    } 

    const safeHandlePress = () => {
        if(onChangeText && typeof onChangeText === 'function'){ onChangeText(typedText) ;}
    }

    const safeHandleSearch = () => {
        if(onSearch && typeof onSearch === 'function'){ onSearch(typedText) ;}
    }

    useEffect(() => {
        safeHandlePress()
    }, [typedText])

    return (
        <>  
            <ContentKeyboard>
                {
                    (mode === 1 ? availableKeysSide1 : availableKeysSide2).map((item, key) => item.empty ? <EmptyKey /> :
 
                        <KeyboardKey key={key} action={ item.action || item.icon } search={item.search} space={item.space} onPress={() => action(item)} onEnterPress={() => action(item)}>
                            { item?.icon?.icon === 'shift' && isUpper ? <KeyboardKeyDecoration /> : null }
                            { item.key ? <KeyboardKeyText isUpper={isUpper} >{ item.key }</KeyboardKeyText> : null }
                            { item.action ? <KeyboardKeyText small>{ item.action.title }</KeyboardKeyText> : null }
                            { item.search ? <KeyboardKeyText small>Pesquisar</KeyboardKeyText> : null }
                            { item.icon ? <KeyboardKeyIcon { ...item.icon } /> : null }
                        </KeyboardKey>
                    )
                }
            </ContentKeyboard>  
        </>
    )
}