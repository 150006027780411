import React, { useContext, useState } from "react";
import { CoreContext } from "../../context/CoreContext";
import { availableVideos } from "../../services/mock";
import ButtonIcon from "../ButtonIcon";

import { 
    ContentBanner,
    BannerDecoration,
    BannerContent,
    BannerContentTitle,
    BannerContentText,
    BannerRowOptions,
    BannerButton,
    BannerButtonIcon,
    BannerButtonText, 
} from './styled' 

export default function Banner({ banners }){

    const { setCurrentVideo, isFavorite, toggleFavorite, favoriting } = useContext(CoreContext) 

    const currentBanner = banners?.[0]
    console.log({currentBanner})
    return (
        <> 
            <ContentBanner source={currentBanner?.image}>
                <BannerDecoration />
                <BannerContent>
                    <BannerContentTitle>{ currentBanner?.title }</BannerContentTitle>
                    <BannerContentText>{ currentBanner?.description }</BannerContentText>
                    <BannerRowOptions>
                        <ButtonIcon focusKey={'button-banner'} onPress={() => setCurrentVideo(currentBanner)} onEnterPress={() => setCurrentVideo(currentBanner)} icon={'play'} >
                            Assistir
                        </ButtonIcon>
                        
                        {/* <ButtonIcon loading={favoriting} focusKey={'button-banner-fav'} onPress={() => toggleFavorite(currentBanner)} onEnterPress={() => toggleFavorite(currentBanner)} icon={'heart'} spaced >
                            { isFavorite(currentBanner) ? `Remover dos favoritos` : `Adicionar aos favoritos`}
                        </ButtonIcon> */}
                    </BannerRowOptions>
                </BannerContent>
            </ContentBanner>
        </>
    )
}