import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const RecommendationContainer = styled.View.attrs({
})`     
    background: rgba(0,0,0,.9);
    margin: 0 0 -${getScaledValue(24)}px;
    padding: ${getScaledValue(24)}px ${getScaledValue(24)}px;
`;     
export const RecommendationTitle = styled.Text.attrs({
})`    
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(14)}px;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(8) }px;
`;     
export const RecommendationContent = styled.View.attrs({
    horizontal: true,
    showsHorizontalScrollIndicator:false
})`    
    flex-direction: row;
`;     
export const RecommendationVideo = withFocusable()(styled.TouchableOpacity.attrs({
})`      
    border-width: ${ getScaledValue(2) }px;
    border-color: transparent;
    ${
        props => props.focused ? `
            border-width: ${ getScaledValue(2) }px;
            border-color: ${ props.theme.primary || '#f95e14' };
        ` : ``
    }
    margin-right: ${ getScaledValue(12) }px;
`);
export const RecommendationVideoImage = styled.ImageBackground.attrs({
})`     
    width: ${ getScaledValue(180) }px;
    height: ${ getScaledValue(100) }px;
    background: #ffffff;
`;     