import styled from 'styled-components/native'    

import Icons from './../../assets/icons'
import { getScaledValue } from '../../ui/styled';
 

// import {
//     getScaledValue
// } from 'renative'; 
 
export const SideItem = styled.TouchableHighlight.attrs({
})`      
width: ${ getScaledValue(170) }px;
height: ${ getScaledValue(70) }px;
background: #35245D;
margin-bottom: ${ getScaledValue(12) }px;
flex-direction: row;
align-items: center;
border-radius:  ${ getScaledValue(6) }px; 
${
    props => props.active ? `
        border-width: ${ getScaledValue(3) }px;
        border-color: ${ props.theme.primary || '#f95e14' };
        box-shadow: 0px 1px 3px ${ props.theme.primary || '#f95e14' };
    ` : ``
 }
`;     
export const SideItemImage = styled.ImageBackground.attrs({
    resizeMode: 'cover',
    source: require('./../../assets/videos/video-13.png')
})`      
    width: ${ getScaledValue(50) }px;
    ${
        props => props.active ? `
            height: ${ getScaledValue(64) }px;
        ` : `
            height: ${ getScaledValue(70) }px;
        `
     }
    background: #fff;
`;     
export const SideText = styled.Text.attrs({
})`      
    font-family: PTSans-Regular;
    font-size: ${ getScaledValue(12)}px;
    padding: 0 ${ getScaledValue(12)}px;
    color: #ffffff;
`;     

 