import styled from 'styled-components/native'    
// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';
 
import { withFocusable } from '@noriginmedia/react-spatial-navigation'; 

export const ContentInput = styled.View.attrs({
})`      
    background: #ffffff;   

    border-radius: ${ getScaledValue(25) }px;
    flex-direction: row;
    align-items: center;
    padding: 0 ${ getScaledValue(16) }px;  

    margin-bottom: ${ getScaledValue(21) }px; 
`;   
export const InputIcon = styled(Icons).attrs({
    icon: 'search',
    stroke: '#35245D',
    width: getScaledValue(16),
    height: getScaledValue(16),

})`      
`;   
export const InputInput = styled.TextInput.attrs({
    placeholderTextColor:'rgba(0,0,0,.5)',
    editable: false
})`      
    height: ${ getScaledValue(50) }px; 
    font-size: ${ getScaledValue(18) }px;
    color: #35245D;
    flex:1;
`;    
export const InputRemove = withFocusable()(styled.TouchableOpacity.attrs({
})`      
    width: ${getScaledValue(30)}px;
    height: ${getScaledValue(30)}px;
    border-radius: ${getScaledValue(15)}px;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : `
            background: rgba(53,36,93,.2);
        `
    }
    align-items: center;
    justify-content: center;
`);    

export const InputRemoveIcon = styled(Icons).attrs({
    icon: 'close',
    stroke: '#ffffff',
    width: getScaledValue(10),
    height: getScaledValue(10),
})`      
`;    










export const ContainerSearchInput = styled.View.attrs({
})`      
${
    props => !props.wrapped ? `
        flex-direction: row;
    ` : ``
}   
`;     

export const ContentHistory = styled.View.attrs({
})`      
    ${
        props => props.wrapped ? `
            margin-top: ${getScaledValue(20) }px;
        ` : ``
    }
`;    
export const HistoryTitle = styled.Text.attrs({
})`      
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Bold;
    color: #ffffff;
    margin-bottom: ${ getScaledValue(14) }px; 
`;    
export const HistoryText = styled.Text.attrs({
})`      
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Regular;
    color: rgba(255,255,255, .5);
    ${
        props => props.active ? `
            color: rgba(255,255,255, 1);
            margin-bottom: ${ getScaledValue(6) }px;
        ` : ``
    }
`;    

export const HistoryAction = withFocusable()(styled.TouchableOpacity.attrs({
})`      
    padding: 10px 10px 0 10px;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : ` 
        `
    }
`);    






 




export const WrapInput = styled.View.attrs({
})`       
    flex:1;
    padding-right: ${ getScaledValue(60) }px;
`; 