import React, { useContext, useEffect } from "react";
import { CoreContext } from "../../../context/CoreContext";
import { availableUsers } from "../../../services/mock";
import ProfileItem from "../../ProfileItem";
import ModalWrapper from "../Wrapper";

import { 
    ContentModalOut,
    ContentModalTitle,
    ContentModalIn,
    UserContent,
    UserContentImage,
    UserContentText
} from './styled'

export default function ModalSelectProfile({ setFocus }){
    
    const { setCurrentProfile, setModal, profiles } = useContext(CoreContext)

    const selectProfile = item => {
        setCurrentProfile(item)
        setModal(null)
    } 
 
    const handleBlurred = () => { 
        
        setModal(null)
    }

    useEffect(() => {  
        if(setFocus && typeof setFocus === 'function'){
            setFocus('profile-1')
        }
    }, []);
    return (
        <>
            <ModalWrapper onBecameBlurred={handleBlurred}>
                <ContentModalOut>
                    <ContentModalTitle>Trocar perfil</ContentModalTitle>
                    <ContentModalIn>
                            {
                                profiles.map((item, key) => 
                                    <ProfileItem key={key} onEnterPress={() => selectProfile(item)} onPress={() => selectProfile(item)} item={item} focusKey={`profile-${ key+1 }`} />
                                )   
                            }
                    </ContentModalIn>
                </ContentModalOut>
            </ModalWrapper>
        </>
    )
}