import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

 
export const SideOption = styled.TouchableHighlight.attrs(props=> ({ 
    underlayColor: props.theme.primary || '#f95e14' 
}))`    
    width: ${ getScaledValue(26) }px;
    height: ${ getScaledValue(26) }px; 
    border-radius: ${ getScaledValue(13) }px; 
    background: #000000;
    align-items: center;
    justify-content: center;

    ${
        props => props.active ? `
            width: ${ getScaledValue(42) }px;
            height: ${ getScaledValue(42) }px; 
            background: ${ props.theme.primary || '#f95e14' };
            border-radius: ${ getScaledValue(21) }px; 
            border-width: ${ getScaledValue(4) }px; 
            border-color: #f95e14;  
            ${
                (props.first || props.last) ? `
                    ${
                        props.first ? `
                            margin: -${ getScaledValue(8) }px 0 -${ getScaledValue(4) }px;
                        ` : ``
                    } 
                    ${
                        props.last ? `
                            margin: -${ getScaledValue(4) }px 0 -${ getScaledValue(8) }px;
                        ` : ``
                    } 
                ` : `
                    margin: -${ getScaledValue(4) }px 0; 
                `
            } 


        ` : ``
    } 
    ${
        props => props.first ? `` : `
            margin-top: ${ getScaledValue(0) }px; 
        `
    } 
`;     
 

export const SideOptionIcon = styled(Icons).attrs(props => ({
    width: props.active ? getScaledValue(16) : getScaledValue(12),
    height: props.active ? getScaledValue(16) :  getScaledValue(12),
}))`    
`;     