import styled from 'styled-components/native'    
// import { getScaledValue } from 'renative'; 

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';
  
import { withFocusable } from '@noriginmedia/react-spatial-navigation';

export const ContainerThemes = styled.View.attrs({
})`      
`;    
export const ThemesTitle = styled.Text.attrs({
})`      
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Bold;
    color: #ffffff;
    margin-top: ${ getScaledValue(38) }px; 
    margin-bottom: ${ getScaledValue(10) }px; 
`;    
export const ContentThemes = styled.View.attrs({
    horizontal: true
})`   
    flex-direction: row;
`;    
export const ThemeItem = withFocusable()(styled.TouchableOpacity.attrs({
})`      
    padding: ${ getScaledValue(6) }px ${ getScaledValue(8) }px;
    background: #f95e14;
    ${
        props => props.focused ? `
            background: ${ props.theme.primary || '#f95e14' };
        ` : ``
    }
    border-radius: ${ getScaledValue(2) }px;
    margin-right: ${ getScaledValue(13) }px; 
    margin-bottom: ${ getScaledValue(13) }px; 

`);    
export const ThemeItemText = styled.Text.attrs({
})`      
    font-size: ${ getScaledValue(13) }px; 
    font-family: PTSans-Regular;
    color: #ffffff;
`;    

