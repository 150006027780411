import { GET, POST, PUT, DELETE } from './api'

export const CreateProfile = async (params, token) => {
    return await POST(`/profiles`, params, token);
}

export const ReadProfiles = async (token) => {
    return await GET(`/profiles?populate[0]=picture`, token);
}

export const ReadProfilePictures = async (token) => {
    return await GET(`/profile-pictures`, token);
} 

export const UpdateProfile = async (params, id, token) => {
    return await PUT(`/profiles/${id}`, params, token);
}

export const DeleteProfile = async (id, token) => {
    return await DELETE(`/profiles/${ id }`, token);
} 
 