import styled from 'styled-components/native'    

// import { getScaledValue } from 'renative';  

import Icons from './../../../assets/icons' 
import { getScaledValue } from '../../../ui/styled';

export const Container = styled.View.attrs({
})`
    
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;  

    background-color: rgba(22,22,22,.5); 

    align-items: center;
    justify-content: center;

    overflow: hidden;
    transition: all .06s ease;


    max-height: 100vh;
    max-width: 100vw;

    ${
        p => !p.opened ? `
            overflow: hidden;        
            max-height: 0;
            max-width: 0;
        ` : ``
    }
`;
 