import React, { useContext } from "react"; 

import { availableVideos } from "../../../services/mock";
import VideoSide from "../../VideoSide";

import { 
 
    ContentSideMenu,
    ContentSideSide,
    ContentSideTitle,
 
} from './styled'   
export default function VideoRecommendationSide({ relateds }){   

    return ( 
        <>    
            <ContentSideMenu>
                <ContentSideSide>
                    <ContentSideTitle>Relacionados</ContentSideTitle>

                    {
                        relateds.map((item, key) => 
                            <VideoSide key={key} item={item} />
                        )
                    }

                </ContentSideSide>
            </ContentSideMenu>  
        </>
    )
} 
