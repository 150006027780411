import React, { useState, useEffect, useContext } from 'react';
import { getScaledValue } from '../ui/styled';
import { CoreContext } from '../context/CoreContext';

export default function useOnline(){

    const { setModal, isOnline, setIsOnline } = useContext(CoreContext)

    const handleOnline = () => {
        setIsOnline(true);
        setModal(null)
        if(window.location.pathname !== "/video"){
            window.location.reload()
        }
    };

    const handleOffline = () => {
        setIsOnline(false);

        const message = {
            closeable: false,
            title: 'Sem Conexão!',
            text: 'Algo errado com a sua conexão de internet.',
            action: {
                action: () => handleRetry(),
                title:'Tentar novamente'
            },
            icon: {
                icon:'face',
                width: getScaledValue(40),
                height: getScaledValue(40),
                fill: '#fff'
            }
        }

        setModal({ type:'alert', ...message })

    };

    const handleRetry = () => {
        setIsOnline(navigator.onLine); 
        setModal(null)
        if(!navigator.onLine){
            setTimeout(() => {
                handleOffline()
            }, 1200); 
        }
    };

    useEffect(() => {
        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        // Cleanup the event listeners when component unmounts
        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, []);

    return {
        isOnline
    };

};
