import styled from 'styled-components/native'    

import { getScaledValue } from '../../ui/styled';
import Icons from './../../assets/icons'  

// import LinearGradient from 'react-native-linear-gradient'

// import {
//     getScaledValue
// } from 'renative'; 

 

export const ContentBanner = styled.ImageBackground.attrs({
})`     
    position: relative;
    height: ${ getScaledValue(220) }px; 
    background: rgba(255,255,255, .2);
`;  
export const BannerDecoration = styled.View.attrs({ 
})`     
    position: absolute;
    height: ${ getScaledValue(220) }px;  
    width: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,.7) , rgba(0,0,0, 0.1) ); 
`;  
// export const BannerDecoration = styled(LinearGradient).attrs({
//     colors:['#35245D', 'transparent'],
//     end: { x: 1.2, y: 0 },
//     start: { x: 0.1, y: 0 },
// })`     
//     position: absolute;
//     height: ${ getScaledValue(220) }px;  
//     width: 100%;
// `;  
export const BannerContent = styled.View.attrs({
})`     
    background: rgba(0,0,0, .25); 
    height: ${ getScaledValue(220) }px; 
    padding:  ${ getScaledValue(20) }px; 
`;  
export const BannerRowOptions = styled.View.attrs({
})`     
    flex-direction: row;
`;  
export const BannerContentTitle = styled.Text.attrs({
})`     
    font-size:  ${ getScaledValue(24) }px; 
    color: #ffffff;
    font-family: PTSans-Bold;
    margin-bottom: ${ getScaledValue(12) }px;
`;  
export const BannerContentText = styled.Text.attrs({
    numberOfLines: 6
})`     
    font-size:  ${ getScaledValue(10) }px; 
    line-height:  ${ getScaledValue(13) }px; 
    color: #ffffff;
    font-family: PTSans-Regular;
    max-width:  ${ getScaledValue(300) }px; 
    margin-bottom: ${ getScaledValue(30) }px;
`;  
export const BannerButton = styled.TouchableHighlight.attrs(props => ({
    underlayColor: props.theme.primary || '#f95e14' 
}))`     
    padding: ${ getScaledValue(4) }px  ${ getScaledValue(8) }px;  
    align-self: flex-start;
    border-radius: ${ getScaledValue(3) }px;
    
    ${
        props => props.active ? `
            border-color: #ffffff;
            background: ${ props.theme.primary || '#f95e14' };
            border-width: ${ getScaledValue(2) }px;
        ` : `
            background: #ffffff;
        `
    } 

    flex-direction: row;
    align-items: center;

`;  
export const BannerButtonIcon = styled(Icons).attrs(props => ({
    width: getScaledValue(13),
    height: getScaledValue(13),
    icon: props.active ? 'play' : 'playblack',
    fill: !props.active ? '#35245D' : '#ffffff',
}))`     
    margin-right: ${ getScaledValue(4) }px;
`;  
export const BannerButtonText = styled.Text.attrs({
})`     
    font-size:  ${ getScaledValue(14) }px; 
    color: #ffffff;
    font-family: PTSans-Bold;
    padding: 0 ${ getScaledValue(4) }px;  
     
    ${
        props => !props.active ? `
            color: #35245D;
        ` : `` 
    }
`;  